import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { memo, useContext } from 'react'
import { UiContext } from '../../../hooks/UiContext';
import history from '../../../utls/history';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '12% 8%',
    width: '100%'
  },
  text1: {
    color: theme.palette.background.button.red,
    fontSize: 'calc(1.1em + 1vmin)',
    fontWeight: 900,
    marginBottom: '5%',
    // textAlign: 'center'
  },
  text2: {
    color: theme.palette.common.black,
    fontSize: 'calc(.7em + 1vmin)',
    fontWeight: 400,
    marginBottom: '10%',
    // textAlign: 'center'
  },
  btn: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    width: '90%',
    fontWeight: 700,
    borderRadius: 4,
    backgroundColor: theme.palette.background.button.red
  },
  btn1: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    width: '100%',
    fontWeight: 700,
    borderRadius: 4,
    backgroundColor: theme.palette.background.footer.light + ' !important'
  },
  center: {
    textAlign: 'center'
  }
}));

const BlockedModalContainer = () => {

  const classes = useStyles();
  const { modalAction: { closeBlockedModal } } = useContext(UiContext);

  const handleSubmit=()=>{
    closeBlockedModal();
    history.push("/contact-us");
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.flex}>
            <Typography className={classes.text1}>Account Banned</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography className={classes.text2}>
            Your account has been suspended due to some reason. Please contact us to know further details
          </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item lg={8} md={8} sm={8} xs={8} >
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleSubmit}
            >
              Contact Us
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}  className={classes.center}>
            <Button 
              color='primary'
              variant='contained'
              className={classes.btn1}
              onClick={closeBlockedModal}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(BlockedModalContainer);
