import { SIGNUP_DATA, SIGNUP_FORM } from "../../../store/types";
import { combineReducers } from "redux";

export const signupDataReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SIGNUP_DATA.store: 
      return {
        ...state,
        ...payload
      };
    default: 
      return state;
  }
};

export const signupFormEnablerReducer = (state = false, { type, payload }) => {
  switch (type) {
    case SIGNUP_FORM.form: 
    console.log('sdsdsd', payload);
      return payload;
    default: 
      return state;
  }
};

export const authReducer = combineReducers({
  signupData: signupDataReducer,
  signupForm: signupFormEnablerReducer
});