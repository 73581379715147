export const SIGNUP_DATA = {
  store: 'SIGNUP_DATA_store'
};

export const SIGNUP_FORM = {
  form: 'SIGNUP_DATA_form'
};

export const ADD_PRODUCT_FORM = {
  add: 'ADD_PRODUCT_FORM',
  remove: "REMOVE_PRODUCT_FORM"
};

export const CATEGORIES = {
  request: "CATEGORIES.request",
  success: "CATEGORIES.success",
  error: "CATEGORIES.error",
};

export const PRODUCT_LISTING = {
  request: "PRODUCT_LISTING.request",
  success: "PRODUCT_LISTING.success",
  error: "PRODUCT_LISTING.error",
  search:"PRODUCT_LISTING.search"
};

export const FETCH_PRODUCT = {
  request: "FETCH_PRODUCT.request",
  success: "FETCH_PRODUCT.success",
  error: "FETCH_PRODUCT.error",
};

export const FETCH_INTERESTLIST = {
  request: "FETCH_INTERESTLIST.request",
  success: "FETCH_INTERESTLIST.success",
  error: "FETCH_INTERESTLIST.error",
};

export const KEEP_SELECTED_INTEREST_LIST_DATA = "KEEP_SELECTED_INTEREST_LIST_DATA";

export const FETCH_SHOWN_INTERESTLIST = {
  request: "FETCH_SHOWN_INTERESTLIST.request",
  success: "FETCH_SHOWN_INTERESTLIST.success",
  error: "FETCH_SHOWN_INTERESTLIST.error",
};

export const KEEP_SELECTED_SHOWN_INTEREST_LIST_DATA = "KEEP_SELECTED_SHOWN_INTEREST_LIST_DATA";

export const FETCH_SINGLE_INTERESTLIST = {
  request: "FETCH_SINGLE_INTERESTLIST.request",
  success: "FETCH_SINGLE_INTERESTLIST.success",
  error: "FETCH_SINGLE_INTERESTLIST.error",
  remove: "FETCH_SINGLE_INTERESTLIST.remove",
};

export const FETCH_SINGLE_BID = {
  request: "FETCH_SINGLE_BID.request",
  success: "FETCH_SINGLE_BID.success",
  error: "FETCH_SINGLE_BID.error",
};

export const FETCH_FINAL_QUOTE_LIST = {
  request: "FETCH_FINAL_QUOTE_LIST.request",
  success: "FETCH_FINAL_QUOTE_LIST.success",
  error: "FETCH_FINAL_QUOTE_LIST.error",
};

export const KEEP_SELECTED_FINAL_QUOTE_LIST_DATA = "KEEP_SELECTED_FINAL_QUOTE_LIST_DATA";

export const FETCH_BID_LIST = {
  request: "FETCH_BID_LIST.request",
  success: "FETCH_BID_LIST.success",
  error: "FETCH_BID_LIST.error",
};

export const KEEP_SELECTED_BID_LIST_DATA = "KEEP_SELECTED_BID_LIST_DATA";

export const FETCH_WISHLIST_LIST = {
  request: "FETCH_WISHLIST_LIST.request",
  success: "FETCH_WISHLIST_LIST.success",
  error: "FETCH_WISHLIST_LIST.error",
};

export const KEEP_SELECTED_WISHLIST_LIST_DATA = "KEEP_SELECTED_WISHLIST_LIST_DATA";
export const REMOVE_SELECTED_WISHLIST_LIST_DATA = "REMOVE_SELECTED_WISHLIST_LIST_DATA";

export const KEEP_ORDER_FORM = "KEEP_ORDER_FORM";

export const KEEP_ORDER_FORM_REMOVE = "KEEP_ORDER_FORM_REMOVE";

export const FETCH_SINGLE_ORDER = {
  request: "FETCH_SINGLE_ORDER.request",
  success: "FETCH_SINGLE_ORDER.success",
  error: "FETCH_SINGLE_ORDER.error",
};

export const FETCH_ORDER_LIST = {
  request: "FETCH_ORDER_LIST.request",
  success: "FETCH_ORDER_LIST.success",
  error: "FETCH_ORDER_LIST.error",
};

export const KEEP_SELECTED_ORDER_LIST_DATA = "KEEP_SELECTED_ORDER_LIST_DATA";

export const FETCH_SELLER_PRODUCT_LIST = {
  request: "FETCH_SELLER_PRODUCT_LIST.request",
  success: "FETCH_SELLER_PRODUCT_LIST.success",
  error: "FETCH_SELLER_PRODUCT_LIST.error",
};

export const KEEP_SELECTED_SELLER_PRODUCT_LIST_DATA = "KEEP_SELECTED_SELLER_PRODUCT_LIST_DATA";
export const REMOVE_SELECTED_SELLER_PRODUCT_LIST_DATA = "REMOVE_SELECTED_SELLER_PRODUCT_LIST_DATA";

export const EDIT_PRODUCT_FORM = {
  add: 'ADD_EDIT_PRODUCT_FORM',
  remove: "REMOVE_EDIT_PRODUCT_FORM"
};

export const FETCH_CONFIRMED_ORDER_LIST = {
  request: "FETCH_CONFIRMED_ORDER_LIST.request",
  success: "FETCH_CONFIRMED_ORDER_LIST.success",
  error: "FETCH_CONFIRMED_ORDER_LIST.error",
};

export const KEEP_SELECTED_CONFIRMED_ORDER_LIST_DATA = "KEEP_SELECTED_CONFIRMED_ORDER_LIST_DATA";

export const FETCH_ORDER_CANCELLATION_CONFIG = {
  request: "FETCH_ORDER_CANCELLATION_CONFIG.request",
  success: "FETCH_ORDER_CANCELLATION_CONFIG.success",
  error: "FETCH_ORDER_CANCELLATION_CONFIG.error",
};

export const FETCH_ORDER_TRACK_DATA = {
  request: "FETCH_ORDER_TRACK_DATA.request",
  success: "FETCH_ORDER_TRACK_DATA.success",
  error: "FETCH_ORDER_TRACK_DATA.error",
  clear: "FETCH_ORDER_TRACK_DATA.clear"
};

export const CATEGORY_TITLE_IN_PRODUCT_LISTING = {
  add: "CATEGORY_TITLE_IN_PRODUCT_LISTING.add",
  remove: "CATEGORY_TITLE_IN_PRODUCT_LISTING.remove"
};

export const GET_SELECTED_SELLER_PRODUCT_ID = {
  add: "GET_SELECTED_SELLER_PRODUCT_ID.add",
  remove: "GET_SELECTED_SELLER_PRODUCT_ID.remove"
};

export const GET_SELECTED_WISHLIST_ID = {
  add: "GET_SELECTED_WISHLIST_ID.add",
  remove: "GET_SELECTED_WISHLIST_ID.remove"
};

export const GET_SELECTED_INTERESTLIST_ID = {
  add: "GET_SELECTED_INTERESTLIST_ID.add",
  remove: "GET_SELECTED_INTERESTLIST_ID.remove"
};

export const GET_SELECTED_ORDERS_ID = {
  add: "GET_SELECTED_ORDERS_ID.add",
  remove: "GET_SELECTED_ORDERS_ID.remove"
};

export const GET_SELECTED_BIDS_ID = {
  add: "GET_SELECTED_BIDS_ID.add",
  remove: "GET_SELECTED_BIDS_ID.remove"
};

export const GET_SELECTED_FINAL_QUOTES_ID = {
  add: "GET_SELECTED_FINAL_QUOTES_ID.add",
  remove: "GET_SELECTED_FINAL_QUOTES_ID.remove"
};

export const GET_SELECTED_SHOWN_INTEREST_ID = {
  add: "GET_SELECTED_SHOWN_INTEREST_ID.add",
  remove: "GET_SELECTED_SHOWN_INTEREST_ID.remove"
};

export const GET_SELECTED_CONFIRMED_ORDERS_ID = {
  add: "GET_SELECTED_CONFIRMED_ORDERS_ID.add",
  remove: "GET_SELECTED_CONFIRMED_ORDERS_ID.remove"
};

export const FETCH_ALL_NOTIFICATION = {
  request: "FETCH_ALL_NOTIFICATION.request",
  success: "FETCH_ALL_NOTIFICATION.success",
  error: "FETCH_ALL_NOTIFICATION.error",
  clear: "FETCH_ALL_NOTIFICATION.clear"
};