import { makeStyles } from "@material-ui/core";
import React from "react";
import LogoImage from "../../assets/images/common/logo.png";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100px",
    maxWidth: "200px",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.up("md")]: {
      width: "200px",
    },
  },
}));
const Logo = ({ ...props }) => {
  const classes = useStyles();

  return (
    <img src={LogoImage} className={classes.image} alt='logo' {...props} />
  );
};

export default Logo;
