import { combineReducers } from "redux";
import { ADD_PRODUCT_FORM, CATEGORY_TITLE_IN_PRODUCT_LISTING, EDIT_PRODUCT_FORM, FETCH_BID_LIST, FETCH_FINAL_QUOTE_LIST, FETCH_INTERESTLIST, FETCH_PRODUCT, FETCH_SELLER_PRODUCT_LIST, FETCH_SHOWN_INTERESTLIST, FETCH_SINGLE_BID, FETCH_SINGLE_INTERESTLIST, FETCH_WISHLIST_LIST, GET_SELECTED_BIDS_ID, GET_SELECTED_FINAL_QUOTES_ID, GET_SELECTED_INTERESTLIST_ID, GET_SELECTED_SELLER_PRODUCT_ID, GET_SELECTED_SHOWN_INTEREST_ID, GET_SELECTED_WISHLIST_ID, KEEP_SELECTED_BID_LIST_DATA, KEEP_SELECTED_FINAL_QUOTE_LIST_DATA, KEEP_SELECTED_INTEREST_LIST_DATA, KEEP_SELECTED_SELLER_PRODUCT_LIST_DATA, KEEP_SELECTED_SHOWN_INTEREST_LIST_DATA, KEEP_SELECTED_WISHLIST_LIST_DATA, PRODUCT_LISTING, REMOVE_SELECTED_SELLER_PRODUCT_LIST_DATA } from "../../../store/types";

export const productFormState = {
  files: [],
  categoryId: "",
  subCategoryId: "",
  title: "",
  description: "",
  quantity: null,
  unit: "",
  price: null,
  extraProductDetails: [],
  additionalDetails: [],
  sellerPaymentMode: ""
};

const editProductFormState = {
  files: [],
  categoryId: "",
  subCategoryId: "",
  title: "",
  description: "",
  quantity: null,
  unit: "",
  price: null,
  extraProductDetails: [],
  additionalDetails: [],
  sellerPaymentMode: "",
  imageList: []
};

const productListingState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedProductState = {
  isLoading: false,
  data: {},
  error: null,
};

const interestListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedInterestListState = {
  isLoading: false,
  data: {},
  error: null,
};

const shownInterestListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedShownInterestListState = {
  isLoading: false,
  data: {},
  error: null,
};

const singleInterestListState = {
  isLoading: false,
  data: {},
  error: null,
};

const singleBidState = {
  isLoading: false,
  data: {},
  error: null,
};

const finalQuoteListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedFinalQuoteListState = {
  isLoading: false,
  data: {},
  error: null,
};

const bidListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedBidListState = {
  isLoading: false,
  data: {},
  error: null,
};

const wishListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedWishListState = {
  isLoading: false,
  data: {},
  error: null,
};

const sellerProductListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedSellerProductListState = {
  isLoading: false,
  data: {},
  error: null,
};

const categoryTitleInProductListingState = "";

export const productFormReducer = (state = productFormState, { type, payload }) => {
  switch (type) {
    case ADD_PRODUCT_FORM.add: 
      return {
        ...state,
        ...payload
      };
    case ADD_PRODUCT_FORM.remove:
      return {
        ...productFormState
      };
    default: 
      return state;
  }
};

export const productListReducer = (state = productListingState, { type, payload }) => {
  switch (type) {
    case PRODUCT_LISTING.request:
      return {
        ...state,
        isLoading: true
      };
    case PRODUCT_LISTING.search:
      return {
        ...state,
        isLoading: true
      };
    case PRODUCT_LISTING.success:
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case PRODUCT_LISTING.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default:
      return state;
  }
};

export const selectedProductReducer = (state = selectedProductState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCT.request: 
      return {
        ...state,
        data: {},
        isLoading: true
      };
    case FETCH_PRODUCT.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_PRODUCT.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const interestListReducer = (state = interestListState, { type, payload }) => {
  switch (type) {
    case FETCH_INTERESTLIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_INTERESTLIST.success:
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_INTERESTLIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedInterestListReducer = (state = selectedInterestListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_INTEREST_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const shownInterestListReducer = (state = shownInterestListState, { type, payload }) => {
  switch (type) {
    case FETCH_SHOWN_INTERESTLIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_SHOWN_INTERESTLIST.success: 
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_SHOWN_INTERESTLIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedShownInterestListReducer = (state = selectedShownInterestListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_SHOWN_INTEREST_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const singleInterestListReducer = (state = singleInterestListState, { type, payload }) => {
  switch (type) {
    case FETCH_SINGLE_INTERESTLIST.request: 
      return {
        ...state,
        data: {},
        isLoading: true
      };
    case FETCH_SINGLE_INTERESTLIST.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_SINGLE_INTERESTLIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    case FETCH_SINGLE_INTERESTLIST.remove:
      return {
        ...state,
        data: {}
      };
    default: 
      return state;
  }
};

export const singleBidReducer = (state = singleBidState, { type, payload }) => {
  switch (type) {
    case FETCH_SINGLE_BID.request: 
      return {
        ...state,
        data: {},
        isLoading: true
      };
    case FETCH_SINGLE_BID.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_SINGLE_BID.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const finalQuoteListReducer = (state = finalQuoteListState, { type, payload }) => {
  switch (type) {
    case FETCH_FINAL_QUOTE_LIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_FINAL_QUOTE_LIST.success: 
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_FINAL_QUOTE_LIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedFinalQuoteListReducer = (state = selectedFinalQuoteListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_FINAL_QUOTE_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const bidListReducer = (state = bidListState, { type, payload }) => {
  switch (type) {
    case FETCH_BID_LIST.request: 
      return {
        ...state,
        data: [],
        isLoading: true
      };
    case FETCH_BID_LIST.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_BID_LIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedBidListReducer = (state = selectedBidListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_BID_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const wishListReducer = (state = wishListState, { type, payload }) => {
  switch (type) {
    case FETCH_WISHLIST_LIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_WISHLIST_LIST.success: 
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_WISHLIST_LIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedWishListReducer = (state = selectedWishListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_WISHLIST_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const sellerProductListReducer = (state = sellerProductListState, { type, payload }) => {
  switch (type) {
    case FETCH_SELLER_PRODUCT_LIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_SELLER_PRODUCT_LIST.success: 
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_SELLER_PRODUCT_LIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedSellerProductListReducer = (state = selectedSellerProductListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_SELLER_PRODUCT_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    case REMOVE_SELECTED_SELLER_PRODUCT_LIST_DATA: 
      return {
        ...state,
        data: {}
      };
    default: 
      return state;
  }
};

export const productEditFormReducer = (state = editProductFormState, { type, payload }) => {
  switch (type) {
    case EDIT_PRODUCT_FORM.add: 
      return {
        ...state,
        ...payload
      };
    case EDIT_PRODUCT_FORM.remove:
      return {
        ...state
      };
    default: 
      return state;
  }
};

export const categoryTitleInProductListingReducer = (state = categoryTitleInProductListingState, { type, payload }) => {
  switch (type) {
    case CATEGORY_TITLE_IN_PRODUCT_LISTING.add: 
      return payload;
    case CATEGORY_TITLE_IN_PRODUCT_LISTING.remove: 
      return "";
    default: 
      return state;
  }
};

export const getSelectedSellerProductIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_SELLER_PRODUCT_ID.add: 
      return payload;
    case GET_SELECTED_SELLER_PRODUCT_ID.remove: 
      return "";
    default: 
      return state;
  }
};

export const getSelectedWishlistIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_WISHLIST_ID.add: 
      return payload;
    case GET_SELECTED_WISHLIST_ID.remove: 
      return "";
    default: 
      return state;
  }
};

export const getSelectedInterestlistIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_INTERESTLIST_ID.add: 
      return payload;
    case GET_SELECTED_INTERESTLIST_ID.remove: 
      return "";
    default: 
      return state;
  }
};

export const getSelectedBidsIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_BIDS_ID.add: 
      return payload;
    case GET_SELECTED_BIDS_ID.remove: 
      return "";
    default: 
      return state;
  }
};

export const getSelectedFinalQuoteIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_FINAL_QUOTES_ID.add: 
      return payload;
    case GET_SELECTED_FINAL_QUOTES_ID.remove: 
      return "";
    default: 
      return state;
  }
};

export const getSelectedShownInterestIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_SHOWN_INTEREST_ID.add: 
      return payload;
    case GET_SELECTED_SHOWN_INTEREST_ID.remove: 
      return "";
    default: 
      return state;
  }
};


export const productReducer = combineReducers({
  productForm: productFormReducer,
  productListing: productListReducer,
  selectedProduct: selectedProductReducer,
  interestList: interestListReducer,
  selectedInterestList: selectedInterestListReducer,
  shownInterestList: shownInterestListReducer,
  selectedShownInterestList: selectedShownInterestListReducer,
  singleInterestList: singleInterestListReducer,
  singleBid: singleBidReducer,
  finalQuoteList: finalQuoteListReducer,
  selectedFinalQuoteList: selectedFinalQuoteListReducer,
  bidList: bidListReducer,
  selectedBidList: selectedBidListReducer,
  wishList: wishListReducer,
  selectedWishList: selectedWishListReducer,
  sellerProductList: sellerProductListReducer,
  selectedSellerProductList: selectedSellerProductListReducer,
  productEditForm: productEditFormReducer,
  categoryTitleInProductListing: categoryTitleInProductListingReducer,
  getSelectedSellerProductId: getSelectedSellerProductIdReducer,
  getSelectedWishlistId: getSelectedWishlistIdReducer,
  getSelectedInterestlistId: getSelectedInterestlistIdReducer,
  getSelectedBidsId: getSelectedBidsIdReducer,
  getSelectedFinalQuoteId: getSelectedFinalQuoteIdReducer,
  getSelectedShownInterestId: getSelectedShownInterestIdReducer,

});