const DEMO = {
  BLANK_LINK: "#",
};

export default DEMO;

export const storage = {
  auth: "auth",
  refresh: "refresh",
};

export const phoneLengthDigits = 7;
export const passwordMinimumDigits = 8;

export const logoAlt = "SOLX LOGO";

export const myBidLocation = "/mybids";

export const productDynamicFieldTypes = {
  dropdown: "dropdown",
  text: "text",
};

export const maxProductImages = 15;

export const maxBids = 5;

export const orderDeliveryType = {
  doorDelivery: "door_delivery",
  pickFromOffice: "pick_from_office",
};

export const zipLength = 6;

export const paymentMode = {
  COD: "cash_on_delivery",
  IB: "internet_banking",
  PAO: "pay_at_office",
};

export const productStatus = {
  APPROVAL: "approved",
  REJECTED: "rejected",
  PENDING: "pending",
};

export const configs = {
  OFFICE_ADDRESS: "office_address",
  ABOUT_US: "about_us",
  CONTACT_US: "contact_us",
  PRIVACY_POLICY: "privacy_policy",
  CANCELLATION_WINDOW_RANGE: "cancellation_window",
  TRANSACTION_WINDOW_RANGE: "transaction_window",
  DELIVERY_CHARGE: "delivery_charge",
  ORDER_PLACEMENT_CHARGE: "order_placement_charge",
  USER_REGISTRATION_CHARGE: "user_registration_charge",
  PRODUCT_ADDITION_CHARGE: "product_addition_charge",
  PRODUCT_EXTENSION_CHARGE: "product_extension_charge",
};

export const notificationStatus = {
  READ: "read",
  UNREAD: "unread",
  ALL: "all",
};

export const imageUrl = "https://testboapitech.co.in/product";

export const url = "https://testboapitech.co.in/";

export const deliveredBy="SOLX Logistics";
