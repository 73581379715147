import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { authReducer } from '../features/auth/state/AuthReducer';
import { notificationReducer } from '../features/notifications/state/NotificationReducer';
import { orderReducer } from '../features/order/state/OrderReducer';
import { categoryReducer } from '../features/products/state/CategoryReducer';
import { productReducer } from '../features/products/state/ProductReducer';

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authData: authReducer,
    product: productReducer,
    category: categoryReducer,
    order: orderReducer,
    notification: notificationReducer
  });