import httpRequest from "../../../utls/httpRequest";
import { httpResponseFormat } from "../../../utls/httpResponseFormat";

export const fetchConfigApi = async(checks) => {
  try {
    let config = await httpRequest.get('getConfigUrl', {}, checks);
    if (config.status === 200) {
      return httpResponseFormat(false, config.data.data ? config.data.data: [], "Config fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const subscriptionModeApi = async(data) => {
  try {
    let subscribe = await httpRequest.post('subscriptionPaymentModeUrl', data, {}, '');
    if (subscribe.status === 200) {
      return httpResponseFormat(false, subscribe.data.data ? subscribe.data.data : {}, "Payment mode selected successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const updateSubscriptionTransactionApi = async(data) => {
  try {
    let subscribe = await httpRequest.post('updateSubscriptionTransactionUrl', data, {}, '');
    if (subscribe.status === 200) {
      return httpResponseFormat(false, {}, "transaction updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const fetchTransactionApi = async(check) => {
  try {
    let transaction = await httpRequest.get('transactionUrl', {}, check);
    if (transaction.status === 200) {
      return httpResponseFormat(false, transaction.data.data ? transaction.data.data: [], "Transactions fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchZipApi = async() => {
  try {
    let config = await httpRequest.get('getZipsUrl', {}, '');
    if (config.status === 200) {
      return httpResponseFormat(false, config.data.data ? config.data.data: [], "Pincode(s) fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchVillageApi = async(check) => {
  try {
    let config = await httpRequest.get('getVillagesUrl', {}, check);
    if (config.status === 200) {
      return httpResponseFormat(false, config.data.data ? config.data.data: [], "Villages fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchVillageByIdApi = async(check) => {
  try {
    let config = await httpRequest.get('getVillageByIdUrl', {}, check);
    if (config.status === 200) {
      return httpResponseFormat(false, config.data.data ? config.data.data: [], "Village fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchVillagesApi = async () => {
  try {
    let config = await httpRequest.get('getvillageListUrl', {}, '');
    if (config.status === 200) {
      return httpResponseFormat(false, config.data.data ? config.data.data : [], "village fetched successfully");
    }
    return httpResponseFormat(true, {}, "Internal error, please try again");
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchSellerTransactionApi = async(check) => {
  try {
    let transaction = await httpRequest.get('transaction_seller_Url', {}, check);
    if (transaction.status === 200) {
      return httpResponseFormat(false, transaction.data.data ? transaction.data.data: [], "Transactions fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};
