import { FETCH_ALL_NOTIFICATION } from "../../../store/types";

export const fetchNotificationListRequest = (payload) => ({
  type: FETCH_ALL_NOTIFICATION.request,
  payload
});

export const fetchNotificationListSuccess = (payload) => ({
  type: FETCH_ALL_NOTIFICATION.success,
  payload
});

export const fetchNotificationListError = (payload) => ({
  type: FETCH_ALL_NOTIFICATION.error,
  payload
});