/**
 * @function urlConfig
 * key params is for url type
 * params is for parameters which are passed for the api calls
 */
const urlConfig = (key, params) => {
  // const devHost = "https://www.api.solx.ws";
  // const qaHost = "https://www.api.solx.ws";
  // const prodHost = "https://www.api.solx.ws";
  // const devHost = "http://localhost:4000";
  // const qaHost = "http://localhost:4000";
  // const prodHost = "http://localhost:4000";
  const devHost = "https://testboapitech.co.in";
  const qaHost = "https://testboapitech.co.in";
  const prodHost = "https://testboapitech.co.in";

  /**
   * @var urlsDEV for developement urls
   */
  let urlsDEV = {
    loginUrl: `${devHost}/user/login`,
    getOtpUrl: `${devHost}/services/otp/generate`,
    verifyOtpUrl: `${devHost}/services/otp/verify`,
    signupUrl: `${devHost}/user/signup`,
    verifySignupEmailUrl: `${devHost}/services/email/verify`,
    userDetailsUrl: `${devHost}/user`,
    forgetPasswordGenerateOTPUrl: `${devHost}/user/password/forgot_by_email`,
    forgetPasswordVerifyOTPUrl: `${devHost}/user/password/forgot/reset_email`,
    verifyMobileUrl: `${devHost}/user`,
    verifyEmailUrl: `${devHost}/user/email/verify`,
    updateUserUrl: `${devHost}/user/update`,
    generateOTPVerifyMobileUrl: `${devHost}/user/mobile/update`,
    generateOTPVerifyEmailUrl: `${devHost}/user/email/update`,
    changeEmailUrl: `${devHost}/user/email/verify`,
    changeMobileUrl: `${devHost}/user/mobile/verify`,
    generateOTPChangeMobileUrl: `${devHost}/user/mobile/update`,
    generateOTPChangeEmailUrl: `${devHost}/user/email/update`,
    changePasswordUrl: `${devHost}/user/password/update`,
    profilePic: `${devHost}/user/profile/image/update`,
    categoryUrl: `${devHost}/product/category/main/get`,
    subCategoryUrl: `${devHost}/product/category/sub/get?parentId=${params.categoryId}`,
    categoryWithSubCategoryUrl: `${devHost}/product/category/all`,
    addProductUrl: `${devHost}/product/add`,
    productListingUrl: `${devHost}/product/list?category=${params.category}&perPage=${params.perPage}&page=${params.page}&price_from=${params.price_from}&price_to=${params.price_to}&sort=${params.sort}&searchTerm=${params.search}`,
    productUrl: `${devHost}/product/item?productId=${params}`,
    showInterestOrWishlistUrl: `${devHost}/product/wishlist/add`,
    fetchInterestOrWishlistUrl: `${devHost}/product/wishlist/list_data?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    removeInterestOrWishlistUrl: `${devHost}/product/wishlist/remove`,
    fetchShownInterestListUrl: `${devHost}/product/seller/interest/list?perPage=${params.perPage}&page=${params.page}`,
    fetchSingleInterestListUrl: `${devHost}/product/wishlist/get-single?wishListId=${params}`,
    bidProductUrl: `${devHost}/product/quote/add`,
    fetchSingleBidUrl: `${devHost}/product/quote/user/get-single?bidId=${params}`,
    fetchFinalQuoteListUrl: `${devHost}/product/quote/seller/list?perPage=${params.perPage}&page=${params.page}`,
    bidAcceptOrRejectUrl: `${devHost}/product/quote/seller/change-status`,
    fetchBidListUrl: `${devHost}/product/quote/user/list?perPage=${params.perPage}&page=${params.page}`,
    removeBidUrl: `${devHost}/product/bid/remove`,
    removeWishlistFromProductUrl: `${devHost}/product/wishlist/remove/product-id`,
    getConfigUrl: `${devHost}/admin/app-config/get-config?configType=${params}`,
    orderUrl: `${devHost}/product/order`,
    singleOrderUrl: `${devHost}/product/order/get-single?orderId=${params}`,
    orderUpdateUrl: `${devHost}/product/order/update/transaction`,
    fetchOrderListUrl: `${devHost}/product/order/list?perPage=${params.perPage}&page=${params.page}`,
    fetchSellerProductListUrl: `${devHost}/product/seller/product-list?perPage=${params.perPage}&page=${params.page}`,
    updateProductUrl: `${devHost}/product/update`,
    productUpdateTransactionUrl: `${devHost}/product/update/transaction`,
    fetchOrderConfirmedListUrl: `${devHost}/product/order/seller/list?perPage=${params.perPage}&page=${params.page}`,
    cancelOrderUrl: `${devHost}/product/order/cancel`,
    trackOrderUrl: `${devHost}/shipment/get-status?orderId=${params}`,
    fetchNotificationUrl: `${devHost}/notification?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    fetchHomeDataUrl: `${devHost}/user/home-data`,
    reportUserUrl: `${devHost}/user/report`,
    fetchSearchUrl: `${devHost}/product/search?searchTerm=${params}&perPage=20&page=1`,
    subscriptionPaymentModeUrl: `${devHost}/user/registration/payment-mode`,
    updateSubscriptionTransactionUrl: `${devHost}/user/registration/update-transaction-id`,
    toggleProductUrl: `${devHost}/product/toggle-disable`,
    deleteProductUrl: `${devHost}/product/delete`,
    deleteUserUrl: `${devHost}/user/delete`,
    transactionUrl: `${devHost}/product/order/transaction/list?filter=${params}&sort=1`,
    getZipsUrl: `${devHost}/services/delivery/pincode/list`,
    getVillagesUrl: `${devHost}/services/delivery/village/list?zip=${params}`,
    getVillageByIdUrl: `${devHost}/services/delivery/get-by-id?id=${params}`,
    fetchSingleSellerProductUrl: `${devHost}/product/seller/product/view?productId=${params}`,
    readNotificationUrl: `${devHost}/notification/mark-all-as-read`,
    fetchSingleFinalQuoteUrl: `${devHost}/product/quote/seller/get-single?id=${params}`,
    fetchSingleShownInterestUrl: `${devHost}/product/seller/interest/get-single?id=${params}`,
    fetchSingleConfirmedOrderUrl: `${devHost}/product/order/seller/get-single?id=${params}`,
    subCategoryPaymentMethodsByIdUrl:`${devHost}/product/subcategory/get-payment-method?subCategoryId=${params.subCategoryId}`,
    wishListByUser:`${devHost}/user/interestList`,
    getvillageListUrl:`${devHost}/services/delivery/villages/list`,
    transaction_seller_Url: `${devHost}/product/order/seller_transaction/list?filter=${params}&sort=1`,
    fetchBidOrderedStatusUrl:`${devHost}/product/bid/get-bid-ordered?quoteId=${params}`,
    resetBanned: `${devHost}/user/reset/blocked_users`,
    trendingList:`${devHost}/product/trending/list`,
    productSearch:`${devHost}/product/list/search`,
    mostViewed:`${devHost}/product/list/most_viewed`,
    addMostViewed:`${devHost}/product/most_viewed`,
    changeMobileWithoutOtpApi:`${devHost}/users/profile/change_mobile`,
    generateInvoice: `${devHost}/user/transaction/generatePdf`,
    contactUsEmail: `${devHost}/category/contact_us`,
    get_banners: `${devHost}/admin/get_banners`,
  };

  /**
   * @var urlsQA for testing urls
   */
  let urlsQA = {
    loginUrl: `${qaHost}/user/login`,
    getOtpUrl: `${qaHost}/services/otp/generate`,
    verifyOtpUrl: `${qaHost}/services/otp/verify`,
    signupUrl: `${qaHost}/user/signup`,
    verifySignupEmailUrl: `${qaHost}/services/email/verify`,
    userDetailsUrl: `${qaHost}/user`,
    forgetPasswordGenerateOTPUrl: `${qaHost}/user/password/forgot_by_email`,
    forgetPasswordVerifyOTPUrl: `${qaHost}/user/password/forgot/reset_email`,
    verifyMobileUrl: `${qaHost}/user`,
    verifyEmailUrl: `${qaHost}/user/email/verify`,
    updateUserUrl: `${qaHost}/user/update`,
    generateOTPVerifyMobileUrl: `${qaHost}/user/mobile/update`,
    generateOTPVerifyEmailUrl: `${qaHost}/user/email/update`,
    changeEmailURL: `${qaHost}/user/email/verify`,
    changeMobileURL: `${qaHost}/user/mobile/verify`,
    generateOTPChangeMobileUrl: `${qaHost}/user/mobile/update`,
    generateOTPChangeEmailUrl: `${qaHost}/user/email/update`,
    changePasswordUrl: `${qaHost}/user/password/update`,
    profilePic: `${qaHost}/user/profile/image/update`,
    categoryUrl: `${qaHost}/product/category/main/get`,
    subCategoryUrl: `${qaHost}/product/category/sub/get?parentId=${params.categoryId}`,
    addProductUrl: `${qaHost}/product/add`,
    categoryWithSubCategoryUrl: `${qaHost}/product/category/all`,
    productListingUrl: `${qaHost}/product/list?category=${params.category}&perPage=${params.perPage}&page=${params.page}&price_from=${params.price_from}&price_to=${params.price_to}&sort=${params.sort}&searchTerm=${params.search}`,
    productUrl: `${qaHost}/product/item`,
    showInterestOrWishlistUrl: `${qaHost}/product/wishlist/add`,
    fetchInterestOrWishlistUrl: `${qaHost}/product/wishlist/list_data?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    removeInterestOrWishlistUrl: `${qaHost}/product/wishlist/remove`,
    fetchShownInterestListUrl: `${qaHost}/product/seller/interest/list?perPage=${params.perPage}&page=${params.page}`,
    fetchSingleInterestListUrl: `${qaHost}/product/wishlist/get-single?wishListId=${params}`,
    bidProductUrl: `${qaHost}/product/quote/add`,
    fetchSingleBidUrl: `${qaHost}/product/quote/user/get-single?bidId=${params}`,
    fetchFinalQuoteListUrl: `${qaHost}/product/quote/seller/list?perPage=${params.perPage}&page=${params.page}`,
    bidAcceptOrRejectUrl: `${qaHost}/product/quote/seller/change-status`,
    fetchBidListUrl: `${qaHost}/product/quote/user/list?perPage=${params.perPage}&page=${params.page}`,
    removeBidUrl: `${qaHost}/product/bid/remove`,
    removeWishlistFromProductUrl: `${qaHost}/product/wishlist/remove/product-id`,
    getConfigUrl: `${qaHost}/admin/app-config/get-config?configType=${params}`,
    orderUrl: `${qaHost}/product/order`,
    singleOrderUrl: `${qaHost}/product/order/get-single?orderId=${params}`,
    orderUpdateUrl: `${qaHost}/product/order/update/transaction`,
    fetchOrderListUrl: `${qaHost}/product/order/list?perPage=${params.perPage}&page=${params.page}`,
    fetchSellerProductListUrl: `${qaHost}/product/seller/product-list?perPage=${params.perPage}&page=${params.page}`,
    updateProductUrl: `${qaHost}/product/update`,
    productUpdateTransactionUrl: `${qaHost}/product/update/transaction`,
    fetchOrderConfirmedListUrl: `${qaHost}/product/order/seller/list?perPage=${params.perPage}&page=${params.page}`,
    cancelOrderUrl: `${qaHost}/product/order/cancel`,
    trackOrderUrl: `${qaHost}/shipment/get-status?orderId=${params}`,
    fetchNotificationUrl: `${qaHost}/notification?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    fetchHomeDataUrl: `${qaHost}/user/home-data`,
    reportUserUrl: `${qaHost}/user/report`,
    fetchSearchUrl: `${qaHost}/product/search?searchTerm=${params}&perPage=20&page=1`,
    subscriptionPaymentModeUrl: `${qaHost}/user/registration/payment-mode`,
    updateSubscriptionTransactionUrl: `${qaHost}/user/registration/update-transaction-id`,
    toggleProductUrl: `${qaHost}/product/toggle-disable`,
    deleteProductUrl: `${qaHost}/product/delete`,
    deleteUserUrl: `${qaHost}/user/delete`,
    transactionUrl: `${qaHost}/product/order/transaction/list?filter=${params}&sort=1`,
    getZipsUrl: `${qaHost}/services/delivery/pincode/list`,
    getVillagesUrl: `${qaHost}/services/delivery/village/list?zip=${params}`,
    getVillageByIdUrl: `${qaHost}/services/delivery/get-by-id?id=${params}`,
    fetchSingleSellerProductUrl: `${qaHost}/product/seller/product/view?productId=${params}`,
    readNotificationUrl: `${qaHost}/notification/mark-all-as-read`,
    fetchSingleFinalQuoteUrl: `${qaHost}/product/quote/seller/get-single?id=${params}`,
    fetchSingleShownInterestUrl: `${qaHost}/product/seller/interest/get-single?id=${params}`,
    fetchSingleConfirmedOrderUrl: `${qaHost}/product/order/seller/get-single?id=${params}`,
    subCategoryPaymentMethodsByIdUrl:`${qaHost}/product/subcategory/get-payment-method?subCategoryId=${params.subCategoryId}`,
    wishListByUser:`${qaHost}/user/interestList`,
    getvillageListUrl:`${qaHost}/services/delivery/villages/list`,
    transaction_seller_Url: `${qaHost}/product/order/seller_transaction/list?filter=${params}&sort=1`,
    fetchBidOrderedStatusUrl:`${qaHost}/product/bid/get-bid-ordered?quoteId=${params}`,
    resetBanned: `${qaHost}/user/reset/blocked_users`,
    trendingList:`${qaHost}/product/trending/list`,
    productSearch:`${qaHost}/product/list/search`,
    mostViewed:`${qaHost}/product/list/most_viewed`,
    addMostViewed:`${qaHost}/product/most_viewed`,
    changeMobileWithoutOtpApi:`${qaHost}/users/profile/change_mobile`,
    generateInvoice: `${qaHost}/user/transaction/generatePdf`,
    contactUsEmail: `${qaHost}/category/contact_us`,
    get_banners: `${qaHost}/admin/get_banners`,
  };

  /**
   * @var urlsPROD for production urls
   */
  let urlsPROD = {
    loginUrl: `${prodHost}/user/login`,
    getOtpUrl: `${prodHost}/services/otp/generate`,
    verifyOtpUrl: `${prodHost}/services/otp/verify`,
    signupUrl: `${prodHost}/user/signup`,
    verifySignupEmailUrl: `${prodHost}/services/email/verify`,
    userDetailsUrl: `${prodHost}/user`,
    forgetPasswordGenerateOTPUrl: `${prodHost}/user/password/forgot_by_email`,
    forgetPasswordVerifyOTPUrl: `${prodHost}/user/password/forgot/reset_email`,
    verifyMobileUrl: `${prodHost}/user`,
    verifyEmailUrl: `${prodHost}/user/email/verify`,
    updateUserUrl: `${prodHost}/user/update`,
    generateOTPVerifyMobileUrl: `${prodHost}/user/mobile/update`,
    generateOTPVerifyEmailUrl: `${prodHost}/user/email/update`,
    changeEmailURL: `${prodHost}/user/email/verify`,
    changeMobileURL: `${prodHost}/user/mobile/verify`,
    generateOTPChangeMobileUrl: `${prodHost}/user/mobile/update`,
    generateOTPChangeEmailUrl: `${prodHost}/user/email/update`,
    changePasswordUrl: `${prodHost}/user/password/update`,
    profilePic: `${prodHost}/user/profile/image/update`,
    categoryUrl: `${prodHost}/product/category/main/get`,
    subCategoryUrl: `${prodHost}/product/category/sub/get?parentId=${params.categoryId}`,
    addProductUrl: `${prodHost}/product/add`,
    categoryWithSubCategoryUrl: `${prodHost}/product/category/all`,
    productListingUrl: `${prodHost}/product/list?category=${params.category}&perPage=${params.perPage}&page=${params.page}&price_from=${params.price_from}&price_to=${params.price_to}&sort=${params.sort}&searchTerm=${params.search}`,
    productUrl: `${prodHost}/product/item`,
    showInterestOrWishlistUrl: `${prodHost}/product/wishlist/add`,
    fetchInterestOrWishlistUrl: `${prodHost}/product/wishlist/list_data?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    removeInterestOrWishlistUrl: `${prodHost}/product/wishlist/remove`,
    fetchShownInterestListUrl: `${prodHost}/product/seller/interest/list?perPage=${params.perPage}&page=${params.page}`,
    fetchSingleInterestListUrl: `${prodHost}/product/wishlist/get-single?wishListId=${params}`,
    bidProductUrl: `${prodHost}/product/quote/add`,
    fetchSingleBidUrl: `${prodHost}/product/quote/user/get-single?bidId=${params}`,
    fetchFinalQuoteListUrl: `${prodHost}/product/quote/seller/list?perPage=${params.perPage}&page=${params.page}`,
    bidAcceptOrRejectUrl: `${prodHost}/product/quote/seller/change-status`,
    fetchBidListUrl: `${prodHost}/product/quote/user/list?perPage=${params.perPage}&page=${params.page}`,
    removeBidUrl: `${prodHost}/product/bid/remove`,
    removeWishlistFromProductUrl: `${prodHost}/product/wishlist/remove/product-id`,
    getConfigUrl: `${prodHost}/admin/app-config/get-config?configType=${params}`,
    orderUrl: `${prodHost}/product/order`,
    singleOrderUrl: `${prodHost}/product/order/get-single?orderId=${params}`,
    orderUpdateUrl: `${prodHost}/product/order/update/transaction`,
    fetchOrderListUrl: `${prodHost}/product/order/list?perPage=${params.perPage}&page=${params.page}`,
    fetchSellerProductListUrl: `${prodHost}/product/seller/product-list?perPage=${params.perPage}&page=${params.page}`,
    updateProductUrl: `${prodHost}/product/update`,
    productUpdateTransactionUrl: `${prodHost}/product/update/transaction`,
    fetchOrderConfirmedListUrl: `${prodHost}/product/order/seller/list?perPage=${params.perPage}&page=${params.page}`,
    cancelOrderUrl: `${prodHost}/product/order/cancel`,
    trackOrderUrl: `${prodHost}/shipment/get-status?orderId=${params}`,
    fetchNotificationUrl: `${prodHost}/notification?type=${params.type}&perPage=${params.perPage}&page=${params.page}`,
    fetchHomeDataUrl: `${prodHost}/user/home-data`,
    reportUserUrl: `${prodHost}/user/report`,
    fetchSearchUrl: `${prodHost}/product/search?searchTerm=${params}&perPage=20&page=1`,
    subscriptionPaymentModeUrl: `${prodHost}/user/registration/payment-mode`,
    updateSubscriptionTransactionUrl: `${prodHost}/user/registration/update-transaction-id`,
    toggleProductUrl: `${prodHost}/product/toggle-disable`,
    deleteProductUrl: `${prodHost}/product/delete`,
    deleteUserUrl: `${prodHost}/user/delete`,
    transactionUrl: `${prodHost}/product/order/transaction/list?filter=${params}&sort=1`,
    getZipsUrl: `${prodHost}/services/delivery/pincode/list`,
    getVillagesUrl: `${prodHost}/services/delivery/village/list?zip=${params}`,
    getVillageByIdUrl: `${prodHost}/services/delivery/get-by-id?id=${params}`,
    fetchSingleSellerProductUrl: `${prodHost}/product/seller/product/view?productId=${params}`,
    readNotificationUrl: `${prodHost}/notification/mark-all-as-read`,
    fetchSingleFinalQuoteUrl: `${prodHost}/product/quote/seller/get-single?id=${params}`,
    fetchSingleShownInterestUrl: `${prodHost}/product/seller/interest/get-single?id=${params}`,
    fetchSingleConfirmedOrderUrl: `${prodHost}/product/order/seller/get-single?id=${params}`,
    subCategoryPaymentMethodsByIdUrl:`${prodHost}/product/subcategory/get-payment-method?subCategoryId=${params.subCategoryId}`,
    wishListByUser:`${prodHost}/user/interestList`,
    getvillageListUrl:`${prodHost}/services/delivery/villages/list`,
    transaction_seller_Url: `${prodHost}/product/order/seller_transaction/list?filter=${params}&sort=1`,
    fetchBidOrderedStatusUrl:`${prodHost}/product/bid/get-bid-ordered?quoteId=${params}`,
    resetBanned: `${prodHost}/user/reset/blocked_users`,
    trendingList:`${prodHost}/product/trending/list`,
    productSearch:`${prodHost}/product/list/search`,
    mostViewed:`${prodHost}/product/list/most_viewed`,
    addMostViewed:`${prodHost}/product/most_viewed`,
    changeMobileWithoutOtpApi:`${prodHost}/users/profile/change_mobile`,
    generateInvoice: `${prodHost}/user/transaction/generatePdf`,
    contactUsEmail: `${prodHost}/category/contact_us`,
    get_banners: `${prodHost}/admin/get_banners`,
  };

  return urlsDEV[key];
};

export default urlConfig;
