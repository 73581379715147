import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GuestHeader from "../../components/layout/GuestHeader";
import ConfigurationContainer from "./ConfigurationContainer";
import { UiContext } from "../../hooks/UiContext";
import { fetchConfigApi } from "../config/state/ConfigApi";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    [theme.breakpoints.up("xs")]: {
      width: "90%",
      margin: " 30px auto",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "60%",
      margin: "30px 0 0 90px",
    },
  },
  login: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      paddingLeft: '2%',
      paddingRight: '2%'
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "120px",
    },
    paddingRight: '5%'
  },
  arrowIcon:{
    cursor:"pointer"
  }
}));

const AboutUs = () => {
  const classes = useStyles();
  const { loaderAction, toastrAction } = useContext(UiContext);
  const [contactUs, setContactUs] = useState();
  const history=useHistory();

  useEffect(() => {
    handleConfig();
  }, []);

  const handleConfig = async () => {
    loaderAction.pageLoader(true);
    let { error, data, message } = await fetchConfigApi("about_us");
    if (!error) {
      setContactUs(data.description);
    } else {
      toastrAction.toastrOpen(true, message);
    }
    loaderAction.pageLoader(false);
  }

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <ArrowBackIcon fontSize="large" onClick={()=>history.push("/")} className={classes.arrowIcon}/>
        <GuestHeader>
        </GuestHeader>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.login}>
          <ConfigurationContainer
            isChargeActive={!!contactUs}
            contactUs={contactUs}
            title="About Us"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
