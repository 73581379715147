import createSagaMiddleware from '@redux-saga/core';
import { routerMiddleware } from 'connected-react-router';
import history from '../utls/history';
import { applyMiddleware, createStore, compose } from 'redux';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';

//initialize store state
const initialState = {};

//create sagaMiddleware
const sagaMiddleware = createSagaMiddleware();

//compose redux dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

//include all middlewares
const middlewares = [sagaMiddleware, routerMiddleware(history)];

//compose and apply middlewares
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

//create store with the config
const store = createStore(rootReducer(history), initialState, enhancer);

//run root saga => watch saga
sagaMiddleware.run(rootSaga);

export default store;