import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { memo, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { UiContext } from '../../../hooks/UiContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '12% 8%',
    width: '100%'
  },
  text1: {
    color: theme.palette.background.footer.dark,
    fontSize: 'calc(1.1em + 1vmin)',
    fontWeight: 900,
    marginBottom: '5%',
    textAlign: 'center'
  },
  text2: {
    color: theme.palette.common.black,
    fontSize: 'calc(.7em + 1vmin)',
    fontWeight: 400,
    marginBottom: '10%',
    textAlign: 'center'
  },
  btn: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    width: '85%',
    fontWeight: 700,
    borderRadius: 4
  },
  btn1: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    width: '100%',
    fontWeight: 700,
    borderRadius: 4,
    backgroundColor: theme.palette.background.footer.light + ' !important'
  },
  center: {
    textAlign: 'center'
  }
}));

const SubscribeNowModalContainer = () => {

  const classes = useStyles();
  const { modalAction: { closeSubscribeNowModal } } = useContext(UiContext);
  const history = useHistory();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.flex}>
            <Typography className={classes.text1}>Please subscribe to buy</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography className={classes.text2}>
            You need to subscribe SOLX prime  membership to Buy or Sell items
          </Typography>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item lg={8} md={8} sm={8} xs={8}  className={classes.center}>
            <Button 
              color='primary'
              variant='contained'
              className={classes.btn}
              onClick={() => {
                history.push('/subscribe/now');
                closeSubscribeNowModal()
              }}
            >
              Subscribe Now
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}  className={classes.center}>
            <Button 
              color='primary'
              variant='contained'
              className={classes.btn1}
              onClick={() => closeSubscribeNowModal()}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(SubscribeNowModalContainer);
