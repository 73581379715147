import { FETCH_CONFIRMED_ORDER_LIST, FETCH_ORDER_CANCELLATION_CONFIG, FETCH_ORDER_LIST, FETCH_ORDER_TRACK_DATA, FETCH_SINGLE_ORDER, KEEP_ORDER_FORM, KEEP_ORDER_FORM_REMOVE, KEEP_SELECTED_CONFIRMED_ORDER_LIST_DATA, KEEP_SELECTED_ORDER_LIST_DATA } from "../../../store/types";

export const keepOrderForm = (payload) => ({
  type: KEEP_ORDER_FORM,
  payload
});

export const keepOrderFormRemove = (payload) => ({
  type: KEEP_ORDER_FORM_REMOVE,
  payload
});

export const fetchSingleOrderRequest = (payload) => ({
  type: FETCH_SINGLE_ORDER.request,
  payload
});

export const fetchSingleOrderSuccess = (payload) => ({
  type: FETCH_SINGLE_ORDER.success,
  payload
});

export const fetchSingleOrderError = (payload) => ({
  type: FETCH_SINGLE_ORDER.error,
  payload
});

export const fetchOrderlistRequest = (payload) => ({
  type: FETCH_ORDER_LIST.request,
  payload
});

export const fetchOrderlistSuccess = (payload) => ({
  type: FETCH_ORDER_LIST.success,
  payload
});

export const fetchOrderlistError = (payload) => ({
  type: FETCH_ORDER_LIST.error,
  payload
});

export const keepSelectedOrderList = (payload) => ({
  type: KEEP_SELECTED_ORDER_LIST_DATA,
  payload
});

export const fetchConfirmedOrderlistRequest = (payload) => ({
  type: FETCH_CONFIRMED_ORDER_LIST.request,
  payload
});

export const fetchConfirmedOrderlistSuccess = (payload) => ({
  type: FETCH_CONFIRMED_ORDER_LIST.success,
  payload
});

export const fetchConfirmedOrderlistError = (payload) => ({
  type: FETCH_CONFIRMED_ORDER_LIST.error,
  payload
});

export const keepSelectedConfirmedOrderList = (payload) => ({
  type: KEEP_SELECTED_CONFIRMED_ORDER_LIST_DATA,
  payload
});

export const fetchOrderCancellationConfigRequest = (payload) => ({
  type: FETCH_ORDER_CANCELLATION_CONFIG.request,
  payload
});

export const fetchOrderCancellationConfigSuccess = (payload) => ({
  type: FETCH_ORDER_CANCELLATION_CONFIG.success,
  payload
});

export const fetchOrderCancellationConfigError = (payload) => ({
  type: FETCH_ORDER_CANCELLATION_CONFIG.error,
  payload
});

export const fetchOrderTrackingDataRequest = (payload) => ({
  type: FETCH_ORDER_TRACK_DATA.request,
  payload
});

export const fetchOrderTrackingDataSuccess = (payload) => ({
  type: FETCH_ORDER_TRACK_DATA.success,
  payload
});

export const fetchOrderTrackingDataError = (payload) => ({
  type: FETCH_ORDER_TRACK_DATA.error,
  payload
});


export const fetchOrderTrackingDataClear = () => ({
  type: FETCH_ORDER_TRACK_DATA.clear
});

