import React, { useContext, useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { UserContext } from "../features/user/state/UserContext";
import { isObjectNotEmpty } from "../utls/checks";

const ProtectedRouter = ({ component: Component, ...rest }) => {
  const { user, loading } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!loading && !isObjectNotEmpty(user)) {
      history.push('/')
    }
  }, [user, loading]);

  return (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
      }
    />
  );
};

export default ProtectedRouter;
