import httpRequest from "../../../utls/httpRequest";
import { httpResponseFormat } from "../../../utls/httpResponseFormat";

/**
 * @function userDataApi for fetching current user details
 * @returns user data
 */
 export const userDataApi = async() => {
  try {
    let userData = await httpRequest.get('userDetailsUrl', {}, '');
    if (userData.status === 200) {
      return httpResponseFormat(false, userData.data.user ? userData.data.user: {}, "User data fetched successfully"); 
    }
    return httpResponseFormat(false, {}, "User data fetched successfully"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch user data, please try again");
  }
};

export const userUpdateApi = async (data) => {
  try {
    let userUpdate = await httpRequest.post('updateUserUrl', data, {}, '');
    if (userUpdate.status === 200) {
      return httpResponseFormat(false, userUpdate.data.user ? userUpdate.data.user: {}, "User updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const verifyEmailApi = async (data) => {
  try {
    let verifyEmail = await httpRequest.post('verifyEmailUrl', data, {}, '');
    if (verifyEmail.status === 200) {
      return httpResponseFormat(false, verifyEmail.data.user ? verifyEmail.data.user: {}, "Email verified successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const verifyMobileApi = async (data) => {
  try {
    let verifyMobile = await httpRequest.post('verifyMobileUrl', data, {}, '');
    if (verifyMobile.status === 200) {
      return httpResponseFormat(false, verifyMobile.data.user ? verifyMobile.data.user: {}, "Mobile number verified successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const generateOTPVerifyEmailApi = async (data) => {
  try {
    let generateOTPVerifyEmail = await httpRequest.post('generateOTPVerifyEmailUrl', data, {}, '');
    if (generateOTPVerifyEmail.status === 200) {
      return httpResponseFormat(false, {}, "OTP sent successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const generateOTPVerifyMobileApi = async (data) => {
  try {
    let generateOTPVerifyMobile = await httpRequest.post('generateOTPVerifyMobileUrl', data, {}, '');
    if (generateOTPVerifyMobile.status === 201) {
      return httpResponseFormat(false, {}, "OTP sent successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const changeMobileApi = async (data) => {
  try {
    let changeMobile = await httpRequest.post('changeMobileUrl', data, {}, '');
    if (changeMobile.status === 200) {
      return httpResponseFormat(false, changeMobile.data.user ? changeMobile.data.user: {}, "Phone number changed successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const changeMobileWithoutOtpApi = async (data) => {
  try {
    let changeMobile = await httpRequest.post('changeMobileWithoutOtpApi', data, {}, '');
    if (changeMobile.status === 200) {
      return httpResponseFormat(false, changeMobile.data.user ? changeMobile.data.user: {}, "Phone number changed successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const changeEmailApi = async (data) => {
  try {
    let changeEmail = await httpRequest.post('changeEmailUrl', data, {}, '');
    if (changeEmail.status === 200) {
      return httpResponseFormat(false, changeEmail.data.user ? changeEmail.data.user: {}, "Email changed successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const generateOTPChangeMobileApi = async (data) => {
  try {
    let generateOTPChangeMobile = await httpRequest.post('generateOTPChangeMobileUrl', data, {}, '');
    if (generateOTPChangeMobile.status === 200) {
      return httpResponseFormat(false, {}, "OTP sent successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    console.log('dsdsd', error.response);
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const generateOTPChangeEmailApi = async (data) => {
  try {
    let generateOTPChangeEmail = await httpRequest.post('generateOTPChangeEmailUrl', data, {}, '');
    if (generateOTPChangeEmail.status === 200) {
      return httpResponseFormat(false, {}, "OTP sent successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, 'Internal Error');
  }
};

export const changePasswordApi = async (data) => {
  try {
    let changePassword = await httpRequest.post('changePasswordUrl', data, {}, '');
    if (changePassword.status === 200) {
      return httpResponseFormat(false, {}, "Password changed successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const imageUploadApi = async (data) => {
  try {
    let changeProfilePic = await httpRequest.post('profilePic', data, {'content-type': 'multipart/form-data'}, '');
    if (changeProfilePic.status === 200) {
      return httpResponseFormat(false, changeProfilePic.data.user ? changeProfilePic.data.user: {}, "Profile picture changed successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    console.log('=========+++++++', error.response);
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const reportUserApi = async (data) => {
  try {
    let reportUser = await httpRequest.post('reportUserUrl', data, {}, '');
    if (reportUser.status === 201) {
      return httpResponseFormat(false, {}, "Reported successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const deleteUserApi = async () => {
  try {
    let deleteUser = await httpRequest.post('deleteUserUrl', '', {}, '');
    if (deleteUser.status === 200) {
      return httpResponseFormat(false, {}, "Deleted successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};