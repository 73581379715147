import httpRequest from "../../../utls/httpRequest";
import { httpResponseFormat } from "../../../utls/httpResponseFormat";

export const fetchCategoryApi = async() => {
  try {
    let category = await httpRequest.get('categoryUrl', {}, '');
    if (category.status === 200) {
      return httpResponseFormat(false, category.data.data ? category.data.data: {}, "Category fetched successfully"); 
    }
    return httpResponseFormat(false, {}, "User data fetched successfully"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch category data, please try again");
  }
};

export const fetchSubCategoryApi = async(check) => {
  try {
    let subcategory = await httpRequest.get('subCategoryUrl', {}, check);
    if (subcategory.status === 200) {
      return httpResponseFormat(false, subcategory.data.data ? subcategory.data.data: {}, "Sub category fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "sub category not successfully fetched, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch category data, please try again");
  }
};

export const fetchCategoryWithSubCategoryApi = async() => {
  try {
    let category = await httpRequest.get('categoryWithSubCategoryUrl', {}, '');
    if (category.status === 200) {
      return httpResponseFormat(false, category.data.data ? category.data.data: {}, "Category fetched successfully"); 
    }
    return httpResponseFormat(false, {}, "User data fetched successfully"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch category data, please try again");
  }
};

export const fetchSubCategoryPaymentMethodApi = async(check) => {
  try {
    let subcategory = await httpRequest.get('subCategoryPaymentMethodsByIdUrl', {}, check);
    if (subcategory.status === 200) {
      return httpResponseFormat(false, subcategory.data.data ? subcategory.data.data: {}, "Sub category fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "sub category not successfully fetched, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch category data, please try again");
  }
};