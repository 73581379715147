import Typography from "@material-ui/core/Typography";
import React, { memo } from "react";

const ErrorFallback = () => {
  return (
    <div>
      <Typography>Oops, something went wrong :(</Typography>
      <button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </button>
    </div>
  );
};

export default memo(ErrorFallback);
