import { Button, Divider, Grid, makeStyles, Radio, TextareaAutosize, Typography, withStyles } from '@material-ui/core';
import React, { memo, useContext } from 'react';
import { useState } from 'react';
import MainDialogueBox from '../../../components/layout/MainDialogueBox';
import { bidAcceptOrRejectApi } from '../../products/state/ProductsApi';
import { nanoid } from "nanoid";
import { UiContext } from '../../../hooks/UiContext';
import { useHistory } from 'react-router-dom';
import { reportUserApi } from '../../user/state/UserApi';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  root: {
    margin: '3% 7%',
    width: '100%'
  },
  text1: {
    color: theme.palette.background.footer.light,
    fontSize: 'calc(1em + 1vmin)',
    fontWeight: 900,
    marginBottom: '5%',
    // textAlign: 'center'
  },
  text2: {
    color: theme.palette.common.black,
    fontSize: 'calc(.7em + 1vmin)',
    fontWeight: 400,
    marginBottom: '6%',
    // textAlign: 'center'
  },
  btn: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    width: '90%',
    fontWeight: 700,
    borderRadius: 4,
  },
  btn1: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    // width: '100%',
    fontWeight: 700,
    borderRadius: 4,
    backgroundColor: theme.palette.background.footer.light + ' !important'
  },
  center: {
    textAlign: 'center'
  },
  divider: {
    height: 6,
    marginBottom: '12%'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4%'
  }, 
  subTitle: {
    color: theme.palette.text.grey7,
    fontSize: 'calc(.7em + 1vmin)',
    fontWeight: 700,
  },
  textArea: {
    padding: '2% 5%'
  },
  error: {
    fontSize: 'calc(.35em + 1vmin)',
    color: 'red'
  }
}));

const ActiveRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.mai,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const ReportSellerModal = () => {

  const classes = useStyles();
  const { reportSellerModal, reportSellerModalID,productSeller, modalAction: { closeReportSellerModal }, toastrAction } = useContext(UiContext);
  const [rejectedReason, setRejectedReason] = useState({ value: "", error: "" });
  const [rejectedReasonDescription, setRejectedReasonDescription] = useState({ value: "", error: "" });
  const [isBtnDisabled,setBtnDisabled]=useState(false);
  const textAreaCheck = "My reason not listed";

  const handleReport = async(uid) => {
    if ((rejectedReason.value === textAreaCheck && !rejectedReasonDescription.value) || (!rejectedReason.value)) {
      setRejectedReason({...rejectedReason, error: "Select or enter the reason for not accepting the quote"});
    } else {
      setBtnDisabled(true);
      let checks = {
        reportedBy:uid,
        reportedUser: productSeller,
        reason: rejectedReason.value === textAreaCheck ? rejectedReasonDescription.value : rejectedReason.value
      };
      const { error, message } = await reportUserApi(checks);
      if (!error) {
        toastrAction.toastrOpen(true, message);
        setRejectedReason({ value: "", error: "" });
        setRejectedReasonDescription({ value: "", error: "" });
        closeReportSellerModal();
        setBtnDisabled(false);
      } else {
        toastrAction.toastrOpen(true, message);
        setBtnDisabled(false);
      }
    }
  };

  const handleRejection = (e) => {
    if (e.target.value !== textAreaCheck) {
      setRejectedReasonDescription({ value: "", error: "" });
    }
    setRejectedReason({ value: e.target.value, error: "" });
  };

  const reasonArray = [
    "This seller is not real",
    "Received different product",
    "This seller is fraud",
    "Bad quality products",
    textAreaCheck
  ];

  return (
    <MainDialogueBox
      containerClass={classes.container}
      modalStatus={reportSellerModal}
      maxWidth="sm"
      modalCloseAction={closeReportSellerModal}
    >
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.text1}>Reporting a Seller</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography className={classes.text2}>
              Please mention the reason for Reporting
            </Typography>
            </Grid>
          </Grid>
          {
            reasonArray.map(item => {
              return (
                <Grid container key={nanoid()}>
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.flex} >
                    <ActiveRadio
                      checked={rejectedReason.value === item}
                      onChange={(e) => handleRejection(e)}
                      value={item}
                      name='radio-button-demo'
                      inputProps={{ "aria-label": "B" }}
                    />
                    <Typography className={classes.subTitle}>{item}</Typography>
                  </Grid>
                </Grid>
              )
            })
          }
          {
            rejectedReason.value === textAreaCheck &&
            <Grid container className={classes.textArea}>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  placeholder="Please specify your reason"
                  style={{ width: '100%' }}
                  onChange={(e) => setRejectedReasonDescription({ value: e.target.value, error: "" })}
                  value={rejectedReasonDescription.value}
                />
              </Grid>
            </Grid>
          }
          {
            rejectedReason.error && <Typography className={classes.error}>{rejectedReason.error}</Typography>
          }
          <Grid container className={classes.textArea}>
            <Grid item lg={4} md={4} sm={4} xs={4} >
              <Button
                variant="contained"
                className={classes.btn}
                color="primary"
                onClick={() => handleReport(reportSellerModalID)}
                disabled={isBtnDisabled}
              >
                Send
              </Button>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={4}>
              <Button 
                color='primary'
                variant='contained'
                className={classes.btn1}
                onClick={closeReportSellerModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainDialogueBox>
  );
};

export default memo(ReportSellerModal);
