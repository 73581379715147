import { call, put } from "redux-saga/effects";
import { fetchBidError, fetchBidSuccess, fetchFinalQuoteError, fetchFinalQuoteSuccess, fetchInterestListError, fetchInterestListSuccess, fetchProductError, fetchProductSuccess, fetchSellerProductListError, fetchSellerProductListSuccess, fetchShownInterestListError, fetchShownInterestListSuccess, fetchSingleBidError, fetchSingleBidSuccess, fetchSingleInterestListError, fetchSingleInterestListSuccess, fetchWishlistError, fetchWishlistSuccess, productListError, productListSuccess } from "./ProductAction";
import { fetchBidListApi, fetchFinalQuoteListApi, fetchInterestOrWishlistApi, fetchProductApi, fetchProductListingApi, fetchSellerProductListApi, fetchShownInterestListApi, fetchSingleBidApi, fetchSingleInterestListApi, showInterestOrWishlistApi,productSearch } from "./ProductsApi";

export function* fetchProduct({ payload }) {
  const { data, error, message } = yield call(fetchProductListingApi, payload);
  if (error) {
    yield put(productListError({error: message}));
  } else {
    yield put(productListSuccess(data));
  }
}

export function* fetchSelectedProduct({ payload }) {
  const { data, error, message } = yield call(fetchProductApi, payload);
  if (error) {
    yield put(fetchProductError({error: message}));
  } else {
    yield put(fetchProductSuccess(data));
  }
}

export function* fetchInterestList({ payload }) {
  const { data, error, message } = yield call(fetchInterestOrWishlistApi, payload);
  if (error) {
    yield put(fetchInterestListError({error: message}));
  } else {
    yield put(fetchInterestListSuccess(data));
  }
}

export function* fetchShownInterestList({payload}) {
  const { data, error, message } = yield call(fetchShownInterestListApi, payload);
  if (error) {
    yield put(fetchShownInterestListError({error: message}));
  } else {
    yield put(fetchShownInterestListSuccess(data));
  }
}

export function* fetchSingleInterestList({ payload }) {
  const { data, error, message } = yield call(fetchSingleInterestListApi, payload);
  if (error) {
    yield put(fetchSingleInterestListError({error: message}));
  } else {
    yield put(fetchSingleInterestListSuccess(data[0]));
  }
}

export function* fetchSingleBid({ payload }) {
  const { data, error, message } = yield call(fetchSingleBidApi, payload);
  if (error) {
    yield put(fetchSingleBidError({error: message}));
  } else {
    yield put(fetchSingleBidSuccess(data));
  }
}

export function* fetchFinalQuoteListSaga({ payload }) {
  const { data, error, message } = yield call(fetchFinalQuoteListApi, payload);
  if (error) {
    yield put(fetchFinalQuoteError({error: message}));
  } else {
    yield put(fetchFinalQuoteSuccess(data));
  }
}

export function* fetchBidListSaga({ payload }) {
  const { data, error, message } = yield call(fetchBidListApi, payload);
  if (error) {
    yield put(fetchBidError({error: message}));
  } else {
    yield put(fetchBidSuccess(data));
  }
}

export function* fetchWishListSaga({ payload }) {
  const { data, error, message } = yield call(fetchInterestOrWishlistApi, payload);
  if (error) {
    yield put(fetchWishlistError({error: message}));
  } else {
    yield put(fetchWishlistSuccess(data));
  }
}

export function* fetchSellerProductListSaga({ payload }) {
  const { data, error, message } = yield call(fetchSellerProductListApi, payload);
  if (error) {
    yield put(fetchSellerProductListError({error: message}));
  } else {
    yield put(fetchSellerProductListSuccess(data));
  }
}

export function* fetchSearchProduct({ payload }) {
  const { data, error, message } = yield call(productSearch, payload);
  if (error) {
    yield put(productListError({error: message}));
  } else {
    yield put(productListSuccess(data));
  }
}