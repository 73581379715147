import { ADD_PRODUCT_FORM, CATEGORY_TITLE_IN_PRODUCT_LISTING, EDIT_PRODUCT_FORM, FETCH_BID_LIST, FETCH_FINAL_QUOTE_LIST, FETCH_INTERESTLIST, FETCH_PRODUCT, FETCH_SELLER_PRODUCT_LIST, FETCH_SHOWN_INTERESTLIST, FETCH_SINGLE_BID, FETCH_SINGLE_INTERESTLIST, FETCH_WISHLIST_LIST, GET_SELECTED_BIDS_ID, GET_SELECTED_CONFIRMED_ORDERS_ID, GET_SELECTED_FINAL_QUOTES_ID, GET_SELECTED_INTERESTLIST_ID, GET_SELECTED_ORDERS_ID, GET_SELECTED_SELLER_PRODUCT_ID, GET_SELECTED_SHOWN_INTEREST_ID, GET_SELECTED_WISHLIST_ID, KEEP_SELECTED_BID_LIST_DATA, KEEP_SELECTED_FINAL_QUOTE_LIST_DATA, KEEP_SELECTED_INTEREST_LIST_DATA, KEEP_SELECTED_SELLER_PRODUCT_LIST_DATA, KEEP_SELECTED_SHOWN_INTEREST_LIST_DATA, KEEP_SELECTED_WISHLIST_LIST_DATA, PRODUCT_LISTING, REMOVE_SELECTED_SELLER_PRODUCT_LIST_DATA } from "../../../store/types";

export const keepProductFormData = (payload) => ({
  type: ADD_PRODUCT_FORM.add,
  payload
});

export const removeProductFormData = (payload) => ({
  type: ADD_PRODUCT_FORM.remove,
  payload
});

export const productListRequest = (payload) => ({
  type: PRODUCT_LISTING.request,
  payload
});

export const productListSuccess = (payload) => ({
  type: PRODUCT_LISTING.success,
  payload
});

export const productListError = (payload) => ({
  type: PRODUCT_LISTING.error,
  payload
});

export const fetchProductRequest = (payload) => ({
  type: FETCH_PRODUCT.request,
  payload
});

export const fetchProductSuccess = (payload) => ({
  type: FETCH_PRODUCT.success,
  payload
});

export const fetchProductError = (payload) => ({
  type: FETCH_PRODUCT.error,
  payload
});

export const fetchInterestListRequest = (payload) => ({
  type: FETCH_INTERESTLIST.request,
  payload
});

export const fetchInterestListSuccess = (payload) => ({
  type: FETCH_INTERESTLIST.success,
  payload
});

export const fetchInterestListError = (payload) => ({
  type: FETCH_INTERESTLIST.error,
  payload
});

export const keepSelectedInterestList = (payload) => ({
  type: KEEP_SELECTED_INTEREST_LIST_DATA,
  payload
});

export const fetchShownInterestListRequest = (payload) => ({
  type: FETCH_SHOWN_INTERESTLIST.request,
  payload
});

export const fetchShownInterestListSuccess = (payload) => ({
  type: FETCH_SHOWN_INTERESTLIST.success,
  payload
});

export const fetchShownInterestListError = (payload) => ({
  type: FETCH_SHOWN_INTERESTLIST.error,
  payload
});

export const keepSelectedShownInterestList = (payload) => ({
  type: KEEP_SELECTED_SHOWN_INTEREST_LIST_DATA,
  payload
});

export const fetchSingleInterestListRequest = (payload) => ({
  type: FETCH_SINGLE_INTERESTLIST.request,
  payload
});

export const fetchSingleInterestListSuccess = (payload) => ({
  type: FETCH_SINGLE_INTERESTLIST.success,
  payload
});

export const fetchSingleInterestListError = (payload) => ({
  type: FETCH_SINGLE_INTERESTLIST.error,
  payload
});

export const fetchSingleInterestListRemove = () => ({
  type: FETCH_SINGLE_INTERESTLIST.remove
});

export const fetchSingleBidRequest = (payload) => ({
  type: FETCH_SINGLE_BID.request,
  payload
});

export const fetchSingleBidSuccess = (payload) => ({
  type: FETCH_SINGLE_BID.success,
  payload
});

export const fetchSingleBidError = (payload) => ({
  type: FETCH_SINGLE_BID.error,
  payload
});

export const fetchFinalQuoteRequest = (payload) => ({
  type: FETCH_FINAL_QUOTE_LIST.request,
  payload
});

export const fetchFinalQuoteSuccess = (payload) => ({
  type: FETCH_FINAL_QUOTE_LIST.success,
  payload
});

export const fetchFinalQuoteError = (payload) => ({
  type: FETCH_FINAL_QUOTE_LIST.error,
  payload
});

export const keepSelectedFinalQuoteList = (payload) => ({
  type: KEEP_SELECTED_FINAL_QUOTE_LIST_DATA,
  payload
});

export const fetchBidRequest = (payload) => ({
  type: FETCH_BID_LIST.request,
  payload
});

export const fetchBidSuccess = (payload) => ({
  type: FETCH_BID_LIST.success,
  payload
});

export const fetchBidError = (payload) => ({
  type: FETCH_BID_LIST.error,
  payload
});

export const keepSelectedBidList = (payload) => ({
  type: KEEP_SELECTED_BID_LIST_DATA,
  payload
});

export const fetchWishlistRequest = (payload) => ({
  type: FETCH_WISHLIST_LIST.request,
  payload
});

export const fetchWishlistSuccess = (payload) => ({
  type: FETCH_WISHLIST_LIST.success,
  payload
});

export const fetchWishlistError = (payload) => ({
  type: FETCH_WISHLIST_LIST.error,
  payload
});

export const keepSelectedWishList = (payload) => ({
  type: KEEP_SELECTED_WISHLIST_LIST_DATA,
  payload
});

export const fetchSellerProductListRequest = (payload) => ({
  type: FETCH_SELLER_PRODUCT_LIST.request,
  payload
});

export const fetchSellerProductListSuccess = (payload) => ({
  type: FETCH_SELLER_PRODUCT_LIST.success,
  payload
});

export const fetchSellerProductListError = (payload) => ({
  type: FETCH_SELLER_PRODUCT_LIST.error,
  payload
});

export const keepSelectedSellerProductList = (payload) => ({
  type: KEEP_SELECTED_SELLER_PRODUCT_LIST_DATA,
  payload
});

export const removeSelectedSellerProductList = () => ({
  type: REMOVE_SELECTED_SELLER_PRODUCT_LIST_DATA
});

export const keepEditProductFormData = (payload) => ({
  type: EDIT_PRODUCT_FORM.add,
  payload
});

export const removeEditProductFormData = (payload) => ({
  type: EDIT_PRODUCT_FORM.remove,
  payload
});

export const addCategoryTitleInProductListing = (payload) => ({
  type: CATEGORY_TITLE_IN_PRODUCT_LISTING.add,
  payload
});

export const removeCategoryTitleInProductListing = () => ({
  type: CATEGORY_TITLE_IN_PRODUCT_LISTING.remove
});

export const getSelectedSellerProductId = (payload) => ({
  type: GET_SELECTED_SELLER_PRODUCT_ID.add,
  payload
});

export const removeSelectedSellerProductId = () => ({
  type: GET_SELECTED_SELLER_PRODUCT_ID.remove
});

export const getSelectedWishlistId = (payload) => ({
  type: GET_SELECTED_WISHLIST_ID.add,
  payload
});

export const removeSelectedWishlistId = () => ({
  type: GET_SELECTED_WISHLIST_ID.remove
});

export const getSelectedInterestlistId = (payload) => ({
  type: GET_SELECTED_INTERESTLIST_ID.add,
  payload
});

export const removeSelectedInterestlistId = () => ({
  type: GET_SELECTED_INTERESTLIST_ID.remove
});

export const getSelectedOrderId = (payload) => ({
  type: GET_SELECTED_ORDERS_ID.add,
  payload
});

export const removeSelectedOrderId = () => ({
  type: GET_SELECTED_ORDERS_ID.remove
});

export const getSelectedBidsId = (payload) => ({
  type: GET_SELECTED_BIDS_ID.add,
  payload
});

export const removeSelectedBidsId = () => ({
  type: GET_SELECTED_BIDS_ID.remove
});

export const getSelectedFinalQuoteId = (payload) => ({
  type: GET_SELECTED_FINAL_QUOTES_ID.add,
  payload
});

export const removeSelectedFinalQuoteId = () => ({
  type: GET_SELECTED_FINAL_QUOTES_ID.remove
});

export const getSelectedShownInterestId = (payload) => ({
  type: GET_SELECTED_SHOWN_INTEREST_ID.add,
  payload
});

export const removeSelectedShownInterestId = () => ({
  type: GET_SELECTED_SHOWN_INTEREST_ID.remove
});

export const getSelectedConfirmedOrderId = (payload) => ({
  type: GET_SELECTED_CONFIRMED_ORDERS_ID.add,
  payload
});

export const removeSelectedConfirmedOrderId = () => ({
  type: GET_SELECTED_CONFIRMED_ORDERS_ID.remove
});

export const productSearchRequest = (payload) => ({
  type: PRODUCT_LISTING.search,
  payload
});