import { IconButton, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo, useContext } from 'react';
import { UiContext } from '../hooks/UiContext';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  root: {
    '& div': {
      fontSize: "calc(.5em + 1vmin)"
    }
  }
}));

const Toastr = ({ open, message }) => {

  const { toastrAction } = useContext(UiContext);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    toastrAction.toastrOpen(false, "");
  };


  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
      message={message}
      className={classes.root}
      autoHideDuration={7000}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default memo(Toastr);
