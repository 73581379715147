import { makeStyles, withStyles } from '@material-ui/core';
import React, { memo } from 'react';
import MuiDialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  closeButton: {

  },
  dialogContainer: {
    padding: '5%'
  },
  dialog: {
    backgroundColor: theme.palette.common.white
  }
}));

const Dialog = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent'
  },
  paper: {
    borderRadius: 10,
    boxShadow: 'none',
    width: '100%'
  }
}))(MuiDialog);

const MainDialogueBox = ({ 
  children, enableCloseButton = true, modalStatus = false, containerClass = {}, modalCloseAction, maxWidth = "xs", ...props 
}) => {

  const classes = useStyles();

  const handleClose = () => {
    modalCloseAction();
  }

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalStatus}
        {...props}
      >
        <div className={classes.dialogContainer +' '+ containerClass}>
          {children}
        </div>
      </Dialog>
    </>
  );
};

export default memo(MainDialogueBox);
