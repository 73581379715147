import httpInstance from './httpInterceptor';
import urlConfig from './urlConfig';
import storage from './storage';

httpInstance.defaults.baseURL = '';

/**
 * @function makeRequest
 * @param {*} options is an object which have all the details for an api call 
 * @returns an http call
 */
const makeRequest = async(options) => {
  try {
    return await httpInstance(options);
  } catch (error) {
    throw error;
  }
};

export default {

  /**
   * @function get is a global function for calling get method only having access token
   * @param {*} url have the name or type of the url to be called
   * @param {*} config have the configuration used for the api call
   * @param {*} params have the parameters to be passed for the api call, it can be object/array/variable
   * @returns an http get call
   */
  get: async(url, config, params) => {
    return await makeRequest({
      url: urlConfig(url, params),
      method: 'get',
      ...config,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'Authorization': `bearer ${storage.getAccessToken()}`,
        ...config
      }
    });
  },

  /**
   * @function getPublic is a global function for calling get method without access token
   * @param {*} url have the name or type of the url to be called
   * @param {*} config have the configuration used for the api call
   * @param {*} params have the parameters to be passed for the api call, it can be object/array/variable
   * @returns an http get call
   */
  getPublic: async(url, config, params) => {

    return await makeRequest({
      url: urlConfig(url, params),
      method: 'get',
      ...config,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        ...config
      }
    });
  },

  /**
   * @function post is a global function for calling post method with access token
   * @param {*} url have the name or type of the url to be called
   * @param {*} data have the data to be used for api call
   * @param {*} config have the configuration used for the api call
   * @param {*} params have the parameters to be passed for the api call, it can be object/array/variable
   * @returns an http post call
   */
  post: async(url, data, config= {'content-type': 'application/json'}, params) => {
    return await makeRequest({
      url: urlConfig(url, params),
      method: 'post',
      data,
      ...config,
      headers: {
        'Authorization': `bearer ${storage.getAccessToken()}`,
        ...config
      }
    })
  },

  /**
   * @function postPublic is a global function for calling post method with access token
   * @param {*} url have the name or type of the url to be called
   * @param {*} data have the data to be used for api call
   * @param {*} config have the configuration used for the api call
   * @param {*} params have the parameters to be passed for the api call, it can be object/array/variable
   * @returns an http post call
   */
  postPublic: async(url, data, config, params) => {

    return await makeRequest({
      url: urlConfig(url, params),
      method: 'post',
      data,
      ...config,
      headers: {
        'content-type': 'application/json',
        ...config
      }
    })
  },

};