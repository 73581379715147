import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { memo, useContext } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainDialogueBox from '../../../components/layout/MainDialogueBox';
import { UiContext } from '../../../hooks/UiContext';
import { isObjectNotEmpty } from '../../../utls/checks';
import { fetchBidRequest } from '../../products/state/ProductAction';
import { removeBidApi, removeInterestOrWishlistApi } from '../../products/state/ProductsApi';
import { UserContext } from '../../user/state/UserContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  root: {
    margin: '12% 8%',
    width: '100%'
  },
  text1: {
    color: theme.palette.background.footer.dark,
    fontSize: 'calc(1.1em + 1vmin)',
    fontWeight: 900,
    marginBottom: '5%',
    // textAlign: 'center'
  },
  text2: {
    color: theme.palette.common.black,
    fontSize: 'calc(.7em + 1vmin)',
    fontWeight: 400,
    marginBottom: '10%',
    // textAlign: 'center'
  },
  btn: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    width: '90%',
    fontWeight: 700,
    borderRadius: 4,
    backgroundColor: theme.palette.background.button.red
  },
  btn1: {
    textTransform: 'capitalize',
    color: '#fff !important',
    fontSize: 'calc(.7em + 1vmin)',
    // width: '100%',
    fontWeight: 700,
    borderRadius: 4,
    backgroundColor: theme.palette.background.black2 + ' !important'
  },
  center: {
    textAlign: 'center'
  }
}));

const BidDeletModal = () => {

  const classes = useStyles();
  const { toastrAction, bidDeleteModal, modalAction: { closeBidDeleteModal }, bidDeleteID } = useContext(UiContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDeleteBid = async(id) => {
    let { error, message, data } = await removeBidApi({ bidId: id });
    if (!error) {
      closeBidDeleteModal();
      dispatch(fetchBidRequest({ page: 1, perPage: 10 }));
      history.push("/mybids");
    } else {
      toastrAction.toastrOpen(true, message);
    }
  }

  return (
    <MainDialogueBox
      containerClass={classes.container}
      modalStatus={bidDeleteModal}
      maxWidth="xs"
      modalCloseAction={closeBidDeleteModal}
    >
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.flex}>
              <Typography className={classes.text1}>Are you sure ?</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography className={classes.text2}>
              Are you sure you want to delete this bid.
            </Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item lg={4} md={4} sm={4} xs={4} >
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => handleDeleteBid(bidDeleteID)}
              >
                Delete
              </Button>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={4}>
              <Button 
                color='primary'
                variant='contained'
                className={classes.btn1}
                onClick={closeBidDeleteModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainDialogueBox>
  );
};

export default memo(BidDeletModal);
