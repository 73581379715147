import axios from 'axios';
import { useEffect,useContext } from 'react';
import { UserContext } from "../features/user/state/UserContext"

//creating a variable axiosInstance with axios events
const httpInstance = axios.create();

//middleware for httpInstance requests
httpInstance.interceptors.request.use(async(request) => {
  return request;
}, function (error) {
  console.log('sdsdfsd', error);
  return Promise.reject(error);
});

//middleware for httpInstance response
// httpInstance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   return Promise.reject(error);
// });

const AxiosInterceptor = ({ children }) => {
  const action=useContext(UserContext);
  

  useEffect(() => {
    const resInterceptor = (response) => {
      // console.log("resInterceptor");
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response.status === 401) {
        action.userActions.logout();
      }
      return Promise.reject(error);
    };

    const interceptor = httpInstance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => httpInstance.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export default httpInstance;
export { AxiosInterceptor }