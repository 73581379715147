import { storage } from "./constant";
import { deleteAllCookies, deleteCookie, getCookie, setCookie } from "./cookies";
import { getLocalStorage, removeAllLocalStorage, removeLocalStorage, setLocalStorage } from "./localStorage";

const { auth } = storage;

export default {
  storeAccessToken: (accessToken) => {
    setCookie(auth, accessToken);
  },
  getAccessToken: () => {
    return getCookie(auth);
  },
  removeAccessToken: () => {
    return deleteCookie(auth);
  },
  removeAllToken: () => {
    return deleteAllCookies();
  },
  storeProductSaveData: (key, data) => {
    setLocalStorage(key, data);
  },
  getProductSaveData: (key) => {
    return getLocalStorage(key);
  },
  removeProductSaveData: (key) => {
    return removeLocalStorage(key);
  },
  clearAllLocalData: () => {
    return removeAllLocalStorage();
  },
  storeSubscription: () => {
    setLocalStorage('subscribe', true);
  },
  getSubscription: () => {
    return getLocalStorage('subscribe');
  }, 
  removeSubscription: () => {
    return removeLocalStorage('subscribe');
  },
}