import { combineReducers } from "redux";
import { FETCH_CONFIRMED_ORDER_LIST, FETCH_ORDER_CANCELLATION_CONFIG, FETCH_ORDER_LIST, FETCH_ORDER_TRACK_DATA, FETCH_SINGLE_ORDER, GET_SELECTED_CONFIRMED_ORDERS_ID, GET_SELECTED_ORDERS_ID, KEEP_ORDER_FORM, KEEP_ORDER_FORM_REMOVE, KEEP_SELECTED_CONFIRMED_ORDER_LIST_DATA, KEEP_SELECTED_ORDER_LIST_DATA } from "../../../store/types";

const orderFormState = {
  
};

const singleOrderState = {
  isLoading: false,
  data: {},
  error: ""
}

const orderListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedOrderListState = {
  isLoading: false,
  data: {},
  error: null,
};

const confirmedOrderListState = {
  isLoading: false,
  data: [],
  error: null,
};

const selectedConfirmedOrderListState = {
  isLoading: false,
  data: {},
  error: null,
};

const orderCancellationConfigState = {
  isLoading: false,
  data: {},
  error: null,
};

const orderTrackingDataState = {
  isLoading: false,
  data: [],
  error: null,
};

export const orderFormReducer = (state = orderFormState, { type, payload }) => {
  switch (type) {
    case KEEP_ORDER_FORM: 
      return {
        ...payload
      };
    case KEEP_ORDER_FORM_REMOVE: 
      return {};
    default: 
      return state;
  }
};

export const singleOrderReducer = (state = singleOrderState, { type, payload }) => {
  switch (type) {
    case FETCH_SINGLE_ORDER.request: 
      return {
        ...state,
        isLoading: true
      };
    case FETCH_SINGLE_ORDER.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_SINGLE_ORDER.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const orderListReducer = (state = orderListState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDER_LIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_ORDER_LIST.success: 
      return {
        ...state,
        data: [...state.data, ...payload],
        isLoading: false,
      }
    case FETCH_ORDER_LIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedOrderListReducer = (state = selectedOrderListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_ORDER_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const confirmedOrderListReducer = (state = confirmedOrderListState, { type, payload }) => {
  switch (type) {
    case FETCH_CONFIRMED_ORDER_LIST.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_CONFIRMED_ORDER_LIST.success: 
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_CONFIRMED_ORDER_LIST.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const selectedConfirmedOrderListReducer = (state = selectedConfirmedOrderListState, { type, payload }) => {
  switch (type) {
    case KEEP_SELECTED_CONFIRMED_ORDER_LIST_DATA: 
      return {
        ...state,
        data: payload
      };
    default: 
      return state;
  }
};

export const orderCancellationConfigReducer = (state = orderCancellationConfigState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDER_CANCELLATION_CONFIG.request: 
      return {
        ...state,
        data: {},
        isLoading: true
      };
    case FETCH_ORDER_CANCELLATION_CONFIG.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_ORDER_CANCELLATION_CONFIG.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const orderTrackingDataReducer = (state = orderTrackingDataState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDER_TRACK_DATA.request: 
      return {
        ...state,
        data: {},
        isLoading: true
      };
    case FETCH_ORDER_TRACK_DATA.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case FETCH_ORDER_TRACK_DATA.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    case FETCH_ORDER_TRACK_DATA.clear:
        return {
          isLoading: false,
          data: [],
          error: null
        };
    default: 
      return state;
  }
};

export const getSelectedOrderIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_ORDERS_ID.add: 
      return payload;
    case GET_SELECTED_ORDERS_ID.remove: 
      return "";
    default: 
      return state;
  }
};


export const getSelectedConfirmedOrderIdReducer = (state = "", { type, payload }) => {
  switch (type) {
    case GET_SELECTED_CONFIRMED_ORDERS_ID.add: 
      return payload;
    case GET_SELECTED_CONFIRMED_ORDERS_ID.remove: 
      return "";
    default: 
      return state;
  }
};

export const orderReducer = combineReducers({
  orderForm: orderFormReducer,
  singleOrder: singleOrderReducer,
  orderList: orderListReducer,
  selectedOrderList: selectedOrderListReducer,
  confirmedOrderList: confirmedOrderListReducer,
  selectedConfirmedOrderList: selectedConfirmedOrderListReducer,
  orderCancellationConfig: orderCancellationConfigReducer,
  orderTrackingData: orderTrackingDataReducer,
  getSelectedOrderId: getSelectedOrderIdReducer,
  getSelectedConfirmedOrderId: getSelectedConfirmedOrderIdReducer
});