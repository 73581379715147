import { makeStyles } from '@material-ui/core';
import React, { memo, useContext, useEffect } from 'react';
import MainDialogueBox from '../../../components/layout/MainDialogueBox';
import { UiContext } from '../../../hooks/UiContext';
import { UserContext } from '../state/UserContext';
import BlockedModalContainer from './BlockedModalContainer';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }
}));

const BlockedModal = () => {

  const classes = useStyles();
  const { blockedModal, modalAction: { closeBlockedModal, openBlockedModal } } = useContext(UiContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && user.isBlocked) {
      openBlockedModal();
    }
  }, [user]);

  return (
    <MainDialogueBox
      containerClass={classes.container}
      modalStatus={blockedModal}
      maxWidth="xs"
      modalCloseAction={closeBlockedModal}
    >
      <BlockedModalContainer />
    </MainDialogueBox>
  );
};

export default memo(BlockedModal);
