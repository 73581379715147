import httpRequest from "../../../utls/httpRequest";
import { httpResponseFormat } from "../../../utls/httpResponseFormat";

export const addProductApi = async (data) => {
  try {
    let addProduct = await httpRequest.post('addProductUrl', data, {'content-type': 'multipart/form-data'}, '');
    if (addProduct.status === 201) {
      return httpResponseFormat(false, addProduct.data.data ? addProduct.data.data: {}, "Product created successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    console.log('=========+++++++', error.response);
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const fetchProductListingApi = async(checks) => {
  try {
    let product = await httpRequest.get('productListingUrl', {}, checks);
    if (product.status === 200) {
      return httpResponseFormat(false, product.data.data ? product.data.data: [], "Products fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch product data, please try again");
  }
};

export const fetchProductApi = async(checks) => {
  try {
    let product = await httpRequest.get('productUrl', {}, checks);
    if (product.status === 200) {
      return httpResponseFormat(false, product.data.data ? product.data.data: {}, "Product fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch product data, please try again");
  }
};

export const showInterestOrWishlistApi = async (data) => {
  try {
    let addProduct = await httpRequest.post('showInterestOrWishlistUrl', data, {}, '');
    if (addProduct.status === 201) {
      return httpResponseFormat(false, addProduct.data.data ? addProduct.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    console.log('=========+++++++', error.response);
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const fetchInterestOrWishlistApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchInterestOrWishlistUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const removeInterestOrWishlistApi = async (data) => {
  try {
    let remove = await httpRequest.post('removeInterestOrWishlistUrl', data, {}, '');
    if (remove.status === 201) {
      return httpResponseFormat(false, remove.data.data ? remove.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, 'Internal Error');
  }
};

export const fetchShownInterestListApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchShownInterestListUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const removeWishlistFromProductApi = async (data) => {
  try {
    let remove = await httpRequest.post('removeWishlistFromProductUrl', data, {}, '');
    if (remove.status === 201) {
      return httpResponseFormat(false, remove.data.data ? remove.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const fetchSingleInterestListApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchSingleInterestListUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const bidProductApi = async (data) => {
  try {
    let bid = await httpRequest.post('bidProductUrl', data, {}, '');
    if (bid.status === 200 || bid.status === 201) {
      return httpResponseFormat(false, bid.data.data ? bid.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const fetchSingleBidApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchSingleBidUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data ? list.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const fetchFinalQuoteListApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchFinalQuoteListUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const bidAcceptOrRejectApi = async (data) => {
  try {
    let bid = await httpRequest.post('bidAcceptOrRejectUrl', data, {}, '');
    if (bid.status === 200) {
      return httpResponseFormat(false, bid.data.data ? bid.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, 'Internal Error');
  }
};

export const fetchBidListApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchBidListUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const removeBidApi = async (data) => {
  try {
    let remove = await httpRequest.post('removeBidUrl', data, {}, '');
    if (remove.status === 201) {
      return httpResponseFormat(false, remove.data.data ? remove.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const fetchNewArrivalsApi = async() => {
  try {
    let list = await httpRequest.get('fetchBidListUrl', {}, '');
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const fetchSellerProductListApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchSellerProductListUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : {}, "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const updateProductApi = async (data) => {
  try {
    let updateProduct = await httpRequest.post('updateProductUrl', data, {'content-type': 'multipart/form-data'}, '');
    if (updateProduct.status === 200) {
      return httpResponseFormat(false, updateProduct.data.data ? updateProduct.data.data: {}, "Product updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    console.log('=========+++++++', error.response);
    return httpResponseFormat(true, {}, error.response && error.response.data.message || error.response.data || 'Internal Error');
  }
};

export const productUpdateTransactionApi = async (data) => {
  try {
    let update = await httpRequest.post('productUpdateTransactionUrl', data, {}, '');
    if (update.status === 200) {
      return httpResponseFormat(false, update.data.data ? update.data.data: {}, "Product updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const fetchSearchApi = async(checks) => {
  try {
    let list = await httpRequest.get('fetchSearchUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data.data ? list.data.data.data : [], "listed successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const toggleProductApi = async (data) => {
  try {
    let update = await httpRequest.post('toggleProductUrl', data, {}, '');
    if (update.status === 200) {
      return httpResponseFormat(false, {}, "Product updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const deleteProductApi = async (data) => {
  try {
    let deleteProduct = await httpRequest.post('deleteProductUrl', data, {}, '');
    if (deleteProduct.status === 200) {
      return httpResponseFormat(false, {}, "Product deleted successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const fetchSingleSellerProductApi = async(checks) => {
  try {
    let product = await httpRequest.get('fetchSingleSellerProductUrl', {}, checks);
    if (product.status === 200) {
      return httpResponseFormat(false, product.data.data ? product.data.data : {}, "Product fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const fetchSingleFinalQuoteApi = async(checks) => {
  try {
    let product = await httpRequest.get('fetchSingleFinalQuoteUrl', {}, checks);
    if (product.status === 200) {
      return httpResponseFormat(false, product.data.data ? product.data.data : {}, "Final quote fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const fetchSingleShownInterestApi = async(checks) => {
  try {
    let interest = await httpRequest.get('fetchSingleShownInterestUrl', {}, checks);
    if (interest.status === 200) {
      return httpResponseFormat(false, interest.data.data ? interest.data.data : {}, "Interests fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const fetchBidOrderedApi = async (checks) => {
  try {
    let list = await httpRequest.get('fetchBidOrderedStatusUrl', {}, checks);
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data ? list.data.data : {}, "listed successfully");
    }
    return httpResponseFormat(true, {}, "Internal error, please try again");
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};

export const productSearch = async (data) => {
  try {
    let list = await httpRequest.post('productSearch', data, {}, '');
    if (list.status === 200) {
      return httpResponseFormat(false, list.data.data ? list.data.data: {}, "Action done successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, 'Internal Error');
  }
};

export const addMostViewed = async (data) => {
  try {
    let deleteProduct = await httpRequest.post('addMostViewed', data, {}, '');
    if (deleteProduct.status === 200) {
      return httpResponseFormat(false, {}, "Product added to most viewed list");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error", error && error.response  && error.response.status);
  }
};