import { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/errors/ErrorFallback";
import Toastr from "../components/Toastr";
import { UserContext } from "../features/user/state/UserContext";
import UserHook from "../features/user/state/UserHook";
import { UiContext } from "../hooks/UiContext";
import UiHook from "../hooks/UiHook";
import { isObjectNotEmpty } from "../utls/checks";
import { useHistory } from "react-router-dom";
import SubscribeNowModal from "../features/subscription/components/SubscribeNowModal";
import BlockedModal from "../features/user/components/BlockedModal";
import InterestListDeletModal from "../features/interestList/components/InterestListDeletModal";
import FullPageSpinner from "../components/layout/FullPageSpinner";
import BidDeleteModal from "../features/interestList/components/BidDeleteModal";
import ReportSellerModal from "../features/report/components/ReportSellerModal";

const AppProvider = ({ children }) => {

  const { user, userActions, loading, notifications, } = UserHook();
  const { 
    toastrAction, toastrMsg, openToastr, 
    subscribeNowModal, modalAction, blockedModal,
    interestListDeleteModal, loaderAction, fullPageLoader,
    interestListDeleteProductID, bidDeleteModal, bidDeleteID,
    wishlistDeleteModal, wishlistDeleteID,
    cancelOrderModal, cancelOrderID,
    reportSellerModal, reportSellerModalID,
    productSeller
  } = UiHook();
  const history = useHistory();

  // useEffect(() => {
  //   if (history) {
  //     if (!isObjectNotEmpty(user)) {
  //       history.push('/');
  //     } else {
  //       history.push('/landing');
  //     }
  //   }
  // }, [user]);
  
  return (
    <UiContext.Provider 
      value={{
        toastrAction, subscribeNowModal, 
        modalAction, blockedModal, interestListDeleteModal, 
        loaderAction, fullPageLoader, interestListDeleteProductID,
        bidDeleteModal, bidDeleteID,
        wishlistDeleteModal, wishlistDeleteID,
        cancelOrderModal, cancelOrderID,
        reportSellerModal, reportSellerModalID,
        productSeller
    }}>
      <UserContext.Provider value={{user, userActions, loading, notifications,}}>
        <Toastr 
          open={openToastr}
          message={toastrMsg}
        />
        {
          fullPageLoader && <FullPageSpinner />
        }
        <Suspense
          fallback={<FullPageSpinner />}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {children}
          </ErrorBoundary>
        </Suspense>
        <BlockedModal />
        <ReportSellerModal />
      </UserContext.Provider>
    </UiContext.Provider>
  );
};

export default AppProvider;