import { CATEGORIES } from "../../../store/types";

export const categoryRequest = () => ({
  type: CATEGORIES.request
});

export const categorySuccess = (payload) => ({
  type: CATEGORIES.success,
  payload
});

export const categoryError = (payload) => ({
  type: CATEGORIES.error,
  payload
});