import httpRequest from "../../../utls/httpRequest";
import { httpResponseFormat } from "../../../utls/httpResponseFormat";

export const orderApi = async (data) => {
  try {
    let order = await httpRequest.post('orderUrl', data, {}, '');
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data ? order.data.data: {}, "Product ordered successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    console.log('=========+++++++', error.response);
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const fetchSingleOrderApi = async(checks) => {
  try {
    let order = await httpRequest.get('singleOrderUrl', {}, checks);
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data ? order.data.data: [], "Order fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const orderUpdateApi = async (data) => {
  try {
    let order = await httpRequest.post('orderUpdateUrl', data, {}, '');
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data ? order.data.data: {}, "Product ordered updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const fetchOrderListApi = async(checks) => {
  try {
    let order = await httpRequest.get('fetchOrderListUrl', {}, checks);
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data.data ? order.data.data.data: [], "Order fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchOrderConfirmedListApi = async(checks) => {
  try {
    let order = await httpRequest.get('fetchOrderConfirmedListUrl', {}, checks);
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data.data ? order.data.data.data: [], "Order fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const cancelOrderApi = async (data) => {
  try {
    let order = await httpRequest.post('cancelOrderUrl', data, {}, '');
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data ? order.data.data: {}, "Product order updated successfully");
    } 
    return httpResponseFormat(true, {}, 'Internal Error');
  } catch (error) {
    return httpResponseFormat(true, {}, error && error.response && error.response.data && error.response.data.message || "Internal error");
  }
};

export const trackOrderApi = async(checks) => {
  try {
    let order = await httpRequest.get('trackOrderUrl', {}, checks);
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data.data ? order.data.data.data: [], "Order fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const fetchSingleConfirmedOrderApi = async(checks) => {
  try {
    let order = await httpRequest.get('fetchSingleConfirmedOrderUrl', {}, checks);
    if (order.status === 200) {
      return httpResponseFormat(false, order.data.data ? order.data.data : {}, "Order fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch, please try again");
  }
};


export const generateInvoice = async (data) => {
  try {
    let response = await httpRequest.post("generateInvoice", data, {}, "");
    if (response.status === 200) {
      return httpResponseFormat(false, response, "Action done successfully");
    }
    return httpResponseFormat(true, {}, "Internal error");
  } catch (error) {
    return httpResponseFormat(
      true,
      {},
      error.response && error.response.data && error.response.data.message
    );
  }
}