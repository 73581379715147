import { categoryError, categorySuccess } from "./CategoryAction";
import { fetchCategoryWithSubCategoryApi } from "./CategoryApi";
import { call, put } from "redux-saga/effects";

export function* fetchCategory() {
  const { data, error, message } = yield call(fetchCategoryWithSubCategoryApi);
  if (error) {
    yield put(categoryError({error: message}));
  } else {
    yield put(categorySuccess(data));
  }
}