import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
      footer: { light: "#215273", dark: "#042840" },
      profile: {
        grey: '#efefef',
        greyBg: '#fbfbfb'
      },
      button: {
        red: '#AA0B0B',
        grey: '#2B2B2B'
      },
      grey: '#dcdcdc',
      grey2: '#f2f2f2',
      scroll: '#d9d9d9',
      scrollActive: '#55C595',
      grey3: '#f6f6f6',
      grey4: '#E7E7E7',
      grey5: '#F4F4F4',
      grey6: '#F7F7F7',
      grey7: '#8C8C8C',
      grey8: '#C4C4C4',
      lightRed: '#FC887B',
      black2: '#2A2A2A',
      grey9: '#F1F1F1',
      grey10: '#FAFAFA',
      grey11:  '#6D6D6D',
      grey12: '#D6D6D6',
      grey13: '#E5E5E5'
    },
    primary: {
      main: "#55C595",
      light: "#CFF4D2",
    },
    secondary: {
      main: "#346ad6",
      contrastText: "#fff",
    },
    error: {
      main: "#FC887B",
    },
    text: {
      primary: "#474747",
      secondary: "#B2B2B2",
      green: "#359D9E",
      light: "#fff",
      grey: '#757575',
      grey2: '#686868',
      grey3: '#838383',
      black2: '#2F2F2F',
      grey4: '#D8D8D8',
      grey5: '#777777',
      grey6: '#767676',
      grey7: '#9C9C9C',
      grey8: '#545454'
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"],
    fontSize: 10,
  },
});

export default theme;
