import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  text1: {
    color: theme.palette.background.footer.light,
    fontSize: 'calc(1.2em + 1vmin)',
    fontWeight: 900,
    marginBottom: '3%'
  },
  text2: {
    color: theme.palette.common.black,
    fontSize: 'calc(.6em + 1vmin)',
    fontWeight: 400,
    marginBottom: '1%',
    whiteSpace: "pre-line"
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const ConfigurationContainer = ({contactUs,title }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography className={classes.text1}>{title}</Typography>
        <Typography className={classes.text2}>
          {contactUs}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(ConfigurationContainer);
