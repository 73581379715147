import { FETCH_ALL_NOTIFICATION } from "../../../store/types";
import { combineReducers } from "redux";

const notificationListState = {
  isLoading: false,
  data: [],
  error: null,
};

export const notificationListReducer = (state = notificationListState, { type, payload }) => {
  switch (type) {
    case FETCH_ALL_NOTIFICATION.request: 
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: true,
          data: []
        };
      } else {
        return {
          ...state,
          isLoading: true
        };
      }
    case FETCH_ALL_NOTIFICATION.success: 
      return {
        ...state,
        data: [...state.data, ...payload]
      }
    case FETCH_ALL_NOTIFICATION.error:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const notificationReducer = combineReducers({
  notificationList: notificationListReducer
});