import { ThemeProvider } from '@material-ui/styles';
import GlobalStyles from './components/GlobalStyles';
import AppProvider from './context';
import AppRouter from './route/AppRouter';
import theme from './theme';
import { AxiosInterceptor } from "./utls/httpInterceptor"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppProvider>
        <AxiosInterceptor>
        <AppRouter />
        </AxiosInterceptor>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;
