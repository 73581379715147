import { combineReducers } from "redux";
import { CATEGORIES } from "../../../store/types";

const categoryState = {
  isLoading: false,
  data: [],
  error: null,
};

export const categoryListReducer = (state = categoryState, { type, payload }) => {
  switch (type) {
    // case CATEGORIES.request: 
    //   return {
    //     ...state,
    //     isLoading: true
    //   };
    case CATEGORIES.success: 
      return {
        ...state,
        isLoading: false,
        data: payload
      };
    case CATEGORIES.error:
      return {
        state,
        isLoading: false,
        error: payload.error
      };
    default: 
      return state;
  }
};

export const categoryReducer = combineReducers({
  category: categoryListReducer
});