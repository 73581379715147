import { CircularProgress } from '@material-ui/core';
import React, { memo } from 'react';

const FullPageSpinner = () => {
  return (
    <div style={{
        position: 'fixed', height: '100vh', width: '100%', 
        display: 'flex', justifyContent: 'center', 
        alignItems: 'center', background: 'rgba(0,0,0,0.1)',
        marginTop: '-10vh', zIndex: 100
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default memo(FullPageSpinner);
