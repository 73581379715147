import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../features/user/state/UserContext";
import { isObjectNotEmpty } from "../utls/checks";

const PublicRouter = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isObjectNotEmpty(user) ? (
          <Component {...props} />
        ) : (
          <Redirect  to='/' />
        )
      }
    />
  );
};

export default PublicRouter;
