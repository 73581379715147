import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    html: {
      height: "100%",
      width: "100%",
    },
    body: {
      backgroundColor: "#fff",
      height: "100%",
      width: "100%",
    },
    a: {
      textDecoration: "none",
    },
  })
);

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
