import { lazy } from 'react';
import { Roles } from '../config/roles';
import AboutUs from '../features/aboutus/AboutUs';
import PrivacyPolicy from '../features/aboutus/PrivacyPolicy';

const Home = lazy(() => import('../features/home/screens/Home'));
const Login = lazy(() => import('../features/auth/screens/Login'));
const Register = lazy(() => import('../features/auth/screens/Register'));
const MyProfile = lazy(() => import('../features/user/screen/MyProfile'));
const ChangePassword = lazy(() => import('../features/user/screen/ChangePassword'));
const ChangeEmailOrMobile = lazy(() => import('../features/user/screen/ChangeEmailOrMobile'));
const ChangeEmail = lazy(() => import('../features/user/screen/ChangeEmail'));
const ChangeMobile = lazy(() => import('../features/user/screen/ChangeMobile'));
const DeleteAccount = lazy(() => import('../features/user/screen/DeleteAccount'));
const ForgetPassword = lazy(() => import('../features/auth/screens/ForgetPassword'));
const ForgetPasswordConfirm = lazy(() => import('../features/auth/screens/ForgetPasswordConfirm'));
const ProductListing = lazy(() => import('../features/products/screens/ProductListing'));
const ProductView = lazy(() => import('../features/products/screens/ProductView'));
const InterestList = lazy(() => import('../features/interestList/screens/InterestList'));
const ViewInterestProduct = lazy(() => import('../features/interestList/screens/ViewInterestProduct'));
const InterestlistEnterPrice = lazy(() => import('../features/interestList/screens/InterestlistEnterPrice'));
const InterestlistBidPlaced = lazy(() => import('../features/interestList/screens/BidPlaced'));
const MyBids = lazy(() => import('../features/interestList/screens/MyBids'));
const OrderSummary = lazy(() => import('../features/order/screens/OrderSummary'));
const PlaceOrder = lazy(() => import('../features/order/screens/PlaceOrder'));
const Purchased = lazy(() => import('../features/order/screens/Purchased'));
const ConfirmPayment = lazy(() => import('../features/transaction/screens/ConfirmPayment'));
const MyOrders = lazy(() => import('../features/order/screens/MyOrders'));
const ViewOrderedProduct = lazy(() => import('../features/order/screens/ViewOrderedProduct'));
const SubscribeNow = lazy(() => import('../features/subscription/screens/SubscribeNow'));
const PayByCash = lazy(() => import('../features/subscription/screens/PayByCash'));
const NetBanking = lazy(() => import('../features/subscription/screens/NetBanking'));
const ThankYou = lazy(() => import('../features/subscription/screens/ThankYou'));
const Congratulations = lazy(() => import('../features/subscription/screens/Congratulations'));
const AllNotifications = lazy(() => import('../features/notifications/screens/AllNotifications'));
const FinalQuote = lazy(() => import('../features/order/screens/FinalQuote'));
const FinalQuoteView = lazy(() => import('../features/order/screens/FinalQuoteView'));
const ShownInterest = lazy(() => import('../features/interestList/screens/ShownInterest'));
const ViewInterestShown = lazy(() => import('../features/interestList/screens/ViewInterestShown'));
const AddProducts = lazy(() => import('../features/products/screens/AddProducts'));
const ProductSummary = lazy(() => import('../features/products/screens/ProductSummary'));
const ProductAddingCharge = lazy(() => import('../features/products/screens/ProductAddingCharge'));
const MyWishlist = lazy(() => import('../features/wishlist/screens/Wishlist'));
const ViewWishlist = lazy(() => import('../features/wishlist/screens/ViewWishlist'));
const ProductManagement = lazy(() => import('../features/products/screens/ProductManagement'));
const ViewProductManagement = lazy(() => import('../features/products/screens/ViewProductManagement'));
const ConfirmedOrder = lazy(() => import('../features/order/screens/ConfirmedOrder'));
const ViewConfirmedOrder = lazy(() => import('../features/order/screens/ViewConfirmedOrder'));
const Transactions = lazy(() => import('../features/transaction/screens/Transactions'));
const Subscription = lazy(() => import('../features/subscription/screens/Subscription'));
const ContactUs=lazy(()=>import("../features/contactus/ContactUs"));
const BannedUser=lazy(()=>import("../features/auth/screens/BannerUser"))
const LandingPage=lazy(()=>import("../features/landing"));

export const routes = [
  {
    path: "/",
    component: LandingPage,
    role: Roles.BOTH,
  },
  {
    path: "/landing",
    component: Home,
    role: Roles.BOTH,
  },
  {
    path: "/login",
    component: Login,
    role: Roles.NO_AUTH,
  },
  {
    path: "/register",
    component: Register,
    role: Roles.NO_AUTH,
  },
  {
    path: "/myprofile",
    component: MyProfile,
    role: Roles.AUTH,
  },
  {
    path: "/change/password",
    component: ChangePassword,
    role: Roles.AUTH,
  },
  {
    path: "/change/mobile",
    component: ChangeMobile,
    role: Roles.AUTH,
  },
  {
    path: "/change/email",
    component: ChangeEmail,
    role: Roles.AUTH,
  },
  {
    path: "/change/email/mobile",
    component: ChangeEmailOrMobile,
    role: Roles.AUTH,
  },
  {
    path: "/delete/account",
    component: DeleteAccount,
    role: Roles.AUTH,
  },
  {
    path: "/forgetpassword",
    component: ForgetPassword,
    role: Roles.NO_AUTH,
  },
  {
    path: "/forgetpassword/verify",
    component: ForgetPasswordConfirm,
    role: Roles.NO_AUTH,
  },
  {
    path: "/product/listing",
    component: ProductListing,
    role: Roles.BOTH,
  },
  {
    path: "/product/view",
    component: ProductView,
    role: Roles.BOTH,
  },
  {
    path: "/interestlist",
    component: InterestList,
    role: Roles.AUTH,
  },
  {
    path: "/interestlist/view",
    component: ViewInterestProduct,
    role: Roles.AUTH,
  },
  {
    path: "/interestlist/price",
    component: InterestlistEnterPrice,
    role: Roles.AUTH,
  },
  {
    path: "/interestlist/bid",
    component: InterestlistBidPlaced,
    role: Roles.AUTH,
  },
  {
    path: "/mybids",
    component: MyBids,
    role: Roles.AUTH,
  },
  {
    path: "/order/summary",
    component: OrderSummary,
    role: Roles.AUTH
  },
  {
    path: "/order/placement",
    component: PlaceOrder,
    role: Roles.AUTH
  },
  {
    path: "/product/purchase",
    component: Purchased,
    role: Roles.AUTH
  },
  {
    path: "/confirm/payment",
    component: ConfirmPayment,
    role: Roles.AUTH
  },
  {
    path: "/myorders",
    component: MyOrders,
    role: Roles.AUTH
  },
  {
    path: "/myorders/product",
    component: ViewOrderedProduct,
    role: Roles.AUTH
  },
  {
    path: "/subscribe/now",
    component: SubscribeNow,
    role: Roles.AUTH
  },
  {
    path: "/subscribe/paybycash",
    component: PayByCash,
    role: Roles.AUTH
  },
  {
    path: "/subscribe/netbanking",
    component: NetBanking,
    role: Roles.AUTH
  },
  {
    path: "/subscribed/successfully",
    component: ThankYou,
    role: Roles.AUTH
  },
  {
    path: "/subscribed/congrats",
    component: Congratulations,
    role: Roles.AUTH
  },
  {
    path: "/notifications/all",
    component: AllNotifications,
    role: Roles.AUTH
  },
  {
    path: "/final/quote",
    component: FinalQuote,
    role: Roles.AUTH
  },
  {
    path: "/finalquote/view",
    component: FinalQuoteView,
    role: Roles.AUTH
  },
  {
    path: "/shown/interest",
    component: ShownInterest,
    role: Roles.AUTH
  },
  {
    path: "/showninterest/view",
    component: ViewInterestShown,
    role: Roles.AUTH
  },
  {
    path: "/add/product",
    component: AddProducts,
    role: Roles.AUTH
  },
  {
    path: "/product/summary",
    component: ProductSummary,
    role: Roles.AUTH
  },
  {
    path: "/product/adding/charge",
    component: ProductAddingCharge,
    role: Roles.AUTH,
  },
  {
    path: "/wishlist",
    component: MyWishlist,
    role: Roles.AUTH,
  },
  {
    path: "/wishlist/view",
    component: ViewWishlist,
    role: Roles.AUTH,
  },
  {
    path: "/product/management",
    component: ProductManagement,
    role: Roles.AUTH,
  },
  {
    path: "/productmanagement/view",
    component: ViewProductManagement,
    role: Roles.AUTH,
  },
  {
    path: "/confirmed/orders",
    component: ConfirmedOrder,
    role: Roles.AUTH,
  },
  {
    path: "/confirmedorder/view",
    component: ViewConfirmedOrder,
    role: Roles.AUTH,
  },
  {
    path: "/transactions/view",
    component: Transactions,
    role: Roles.AUTH,
  },
  {
    path: "/subscription",
    component: Subscription,
    role: Roles.AUTH,
  },
  {
    path: "/contact-us",
    component: ContactUs,
    role: Roles.BOTH
  },
  {
    path: "/about-us",
    component: AboutUs,
    role: Roles.BOTH
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    role: Roles.BOTH
  },
  {
    path: "/banned-user",
    component: BannedUser,
    role: Roles.NO_AUTH,
  },
];
