import httpRequest from "../../../utls/httpRequest";
import { httpResponseFormat } from "../../../utls/httpResponseFormat";

export const fetchNotificationApi = async(checks) => {
  try {
    let notification = await httpRequest.get('fetchNotificationUrl', {}, checks);
    if (notification.status === 200) {
      return httpResponseFormat(false, notification.data.data ? notification.data.data: [], "Notifications fetched successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};

export const readNotificationApi = async() => {
  try {
    let notification = await httpRequest.post('readNotificationUrl', {}, {}, '');
    if (notification.status === 200) {
      return httpResponseFormat(false, notification.data.data ? notification.data.data: [], "Notifications read successfully"); 
    }
    return httpResponseFormat(true, {}, "Internal error, please try again"); 
  } catch (error) {
    console.log('sdsd', error.response);
    return httpResponseFormat(true, {}, "Not able to fetch data, please try again");
  }
};