import { call, put } from "redux-saga/effects";
import { fetchConfigApi } from "../../config/state/ConfigApi";
import { fetchConfirmedOrderlistError, fetchConfirmedOrderlistSuccess, fetchOrderCancellationConfigError, fetchOrderCancellationConfigSuccess, fetchOrderlistError, fetchOrderlistSuccess, fetchOrderTrackingDataError, fetchOrderTrackingDataSuccess, fetchSingleOrderError, fetchSingleOrderSuccess } from "./OrderAction";
import { fetchOrderConfirmedListApi, fetchOrderListApi, fetchSingleOrderApi, trackOrderApi } from "./OrderApi";

export function* fetchSingleOrderSaga({ payload }) {
  const { data, error, message } = yield call(fetchSingleOrderApi, payload);
  if (error) {
    yield put(fetchSingleOrderError({error: message}));
  } else {
    yield put(fetchSingleOrderSuccess(data));
  }
}

export function* fetchOrderListSaga({ payload }) {
  const { data, error, message } = yield call(fetchOrderListApi, payload);
  if (error) {
    yield put(fetchOrderlistError({error: message}));
  } else {
    yield put(fetchOrderlistSuccess(data));
  }
}

export function* fetchConfirmedOrderListSaga({ payload }) {
  const { data, error, message } = yield call(fetchOrderConfirmedListApi, payload);
  if (error) {
    yield put(fetchConfirmedOrderlistError({error: message}));
  } else {
    yield put(fetchConfirmedOrderlistSuccess(data));
  }
}

export function* fetchOrderCancellationConfigSaga({ payload }) {
  const { data, error, message } = yield call(fetchConfigApi, payload);
  if (error) {
    yield put(fetchOrderCancellationConfigError({error: message}));
  } else {
    yield put(fetchOrderCancellationConfigSuccess(data));
  }
}

export function* fetchOrderTrackingDataSaga({ payload }) {
  const { data, error, message } = yield call(trackOrderApi, payload);
  if (error) {
    yield put(fetchOrderTrackingDataError({error: message}));
  } else {
    yield put(fetchOrderTrackingDataSuccess(data));
  }
}