import { call, put } from "redux-saga/effects";
import { fetchNotificationListError, fetchNotificationListSuccess } from "./NotificationAction";
import { fetchNotificationApi } from "./NotificationApi";

export function* fetchNotificationListSaga({ payload }) {
  const { data, error, message } = yield call(fetchNotificationApi, payload);
  if (error) {
    yield put(fetchNotificationListError({error: message}));
  } else {
    yield put(fetchNotificationListSuccess(data.data));
  }
}