import { makeStyles } from '@material-ui/core';
import React, { memo, useContext } from 'react';
import { useEffect } from 'react';
import MainDialogueBox from '../../../components/layout/MainDialogueBox';
import { UiContext } from '../../../hooks/UiContext';
import storage from '../../../utls/storage';
import { UserContext } from '../../user/state/UserContext';
import SubscribeNowModalContainer from './SubscribeNowModalContainer';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }
}));

const SubscribeNowModal = () => {

  const classes = useStyles();
  const { subscribeNowModal, modalAction: { closeSubscribeNowModal, openSubscribeNowModal } } = useContext(UiContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && user.isBlocked === false && user.approval !== "approved" && user.approval!=="pending") {
      if (!storage.getSubscription()) {
        openSubscribeNowModal();
        storage.storeSubscription();
      }
    }
  }, [user]);

  return (
    <MainDialogueBox
      containerClass={classes.container}
      modalStatus={subscribeNowModal}
      maxWidth="xs"
      modalCloseAction={closeSubscribeNowModal}
    >
      <SubscribeNowModalContainer />
    </MainDialogueBox>
  );
};


export default memo(SubscribeNowModal);
