import { useCallback, useEffect, useMemo, useState } from 'react';
import { notificationStatus } from '../../../utls/constant';
import storage from '../../../utls/storage';
import { fetchNotificationApi } from '../../notifications/state/NotificationApi';
import { userDataApi } from './UserApi';
import { isObjectNotEmpty } from '../../../utls/checks';

const UserHook = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});

  const userActions = useMemo(() => ({
    userDetails: (data) => {
      setUser(prevState => ({
        ...prevState, ...data
      }));
    },
    notificationUpdate: (data) => {
      setNotifications(prevState => ({
        ...prevState, ...data
      }));
    },
    logout: async () => {
      setUser({});
      storage.removeAllToken();
    }
  }), []);

  const notificationActions = useMemo(() => ({
    // fetchNotification: (data) => {
    //   setNotifications(data);
    // },
  }), []);

  /**
   * @function authCheck
   * token check
   * user data api call
   * splash management
   */
  const authCheck = useCallback(async () => {
    storage.removeSubscription();
    setLoading(true);
    let token = storage.getAccessToken();
    if (token) {
      //For User Details
      let initialUserDetails = await userDataApi();
      if (!initialUserDetails.error && Object.keys(initialUserDetails.data).length > 0) {
        setUser(initialUserDetails.data);
      } else {
        // Toaster(initialUserDetails.message);
      }
    }
    setLoading(false);
    // setSplashLoader(false);
  }, []);

  useEffect(() => {

    async function getNotification() {
      if (isObjectNotEmpty(user)) {
        let getNotifications = await fetchNotificationApi({ type: notificationStatus.UNREAD, page: 1, perPage: 5 });
        if (!getNotifications.error) {
          setNotifications(getNotifications);
        }
      }
    }

    getNotification();
  }, [user]);

  useEffect(() => {
    
    /**
     * @function initialAsyncCalls wraps initial functions
     */
     async function initialAsyncCalls() {
      // calls authCheck function
      await authCheck();
    }

    initialAsyncCalls();

    return () => {
      //unmounting the function
      initialAsyncCalls();
    }

  }, []);

  return { user, userActions, loading, notifications };
};

export default UserHook;