import { takeLatest } from "redux-saga/effects";
import { fetchNotificationListSaga } from "../features/notifications/state/NotificationSaga";
import { fetchConfirmedOrderListSaga, fetchOrderCancellationConfigSaga, fetchOrderListSaga, fetchOrderTrackingDataSaga, fetchSingleOrderSaga } from "../features/order/state/OrderSaga";
import { fetchCategory } from "../features/products/state/CategorySaga";
import { fetchBidListSaga, fetchFinalQuoteListSaga, fetchInterestList, fetchProduct, fetchSelectedProduct, fetchSellerProductListSaga, fetchShownInterestList, fetchSingleBid, fetchSingleInterestList, fetchWishListSaga,fetchSearchProduct } from "../features/products/state/ProductSaga";
import { CATEGORIES, FETCH_ALL_NOTIFICATION, FETCH_BID_LIST, FETCH_CONFIRMED_ORDER_LIST, FETCH_FINAL_QUOTE_LIST, FETCH_INTERESTLIST, FETCH_ORDER_CANCELLATION_CONFIG, FETCH_ORDER_LIST, FETCH_ORDER_TRACK_DATA, FETCH_PRODUCT, FETCH_SELLER_PRODUCT_LIST, FETCH_SHOWN_INTERESTLIST, FETCH_SINGLE_BID, FETCH_SINGLE_INTERESTLIST, FETCH_SINGLE_ORDER, FETCH_WISHLIST_LIST, PRODUCT_LISTING } from "./types";

export function* rootSaga() {
  yield takeLatest(CATEGORIES.request, fetchCategory);
  yield takeLatest(PRODUCT_LISTING.request, fetchProduct);
  yield takeLatest(FETCH_PRODUCT.request, fetchSelectedProduct);
  yield takeLatest(FETCH_INTERESTLIST.request, fetchInterestList);
  yield takeLatest(FETCH_SHOWN_INTERESTLIST.request, fetchShownInterestList);
  yield takeLatest(FETCH_SINGLE_INTERESTLIST.request, fetchSingleInterestList);
  yield takeLatest(FETCH_SINGLE_BID.request, fetchSingleBid);
  yield takeLatest(FETCH_FINAL_QUOTE_LIST.request, fetchFinalQuoteListSaga);
  yield takeLatest(FETCH_BID_LIST.request, fetchBidListSaga);
  yield takeLatest(FETCH_WISHLIST_LIST.request, fetchWishListSaga);
  yield takeLatest(FETCH_SINGLE_ORDER.request, fetchSingleOrderSaga);
  yield takeLatest(FETCH_ORDER_LIST.request, fetchOrderListSaga);
  yield takeLatest(FETCH_SELLER_PRODUCT_LIST.request, fetchSellerProductListSaga);
  yield takeLatest(FETCH_CONFIRMED_ORDER_LIST.request, fetchConfirmedOrderListSaga);
  yield takeLatest(FETCH_ORDER_CANCELLATION_CONFIG.request, fetchOrderCancellationConfigSaga);
  yield takeLatest(FETCH_ORDER_TRACK_DATA.request, fetchOrderTrackingDataSaga);
  yield takeLatest(FETCH_ALL_NOTIFICATION.request, fetchNotificationListSaga);
  yield takeLatest(PRODUCT_LISTING.search,fetchSearchProduct);
}