import Grid from "@material-ui/core/Grid";
import React from "react";
import Logo from "../assets/Logo";

const GuestHeader = ({ children }) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='stretch'
      justify='space-between'
      nowrap
    >
      <Grid item>
        <Logo />
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default GuestHeader;
