import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from '../utls/history';
import { Roles } from '../config/roles';
import { routes } from './routes';
import ProtectedRouter from './ProtectedRouter';
import PublicRouter from './PublicRouter';
import OtherRouter from './OtherRouter';
import { useDispatch, useSelector } from 'react-redux';
import { categoryRequest } from '../features/products/state/CategoryAction';

const AppRouter = () => {

  const dispatch = useDispatch();
  const { data: categoryData } = useSelector(state => state.category.category);

  useEffect(() => {
    if (categoryData.length === 0) {
      dispatch(categoryRequest());
    }
  }, []);

  return (
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <Switch>
          {routes.map(({ path, component, role }, i) => {
            if (role === Roles.AUTH) {
              return (
                <ProtectedRouter key={i} path={path} component={component} exact/>
              );
            } else if (role === Roles.NO_AUTH){
              return (
                <PublicRouter key={i} path={path} component={component}  exact/>
              );
            } else {
              return (
                <OtherRouter key={i} path={path} component={component}  exact/>
              );
            }
          })}
        </Switch>
      </ConnectedRouter>
    </BrowserRouter>
  );
};

export default AppRouter;
