
/**
 * @function setCookie
 * @param {*} name 
 * @param {*} value 
 * @param {*} expDays 
 */
export const setCookie = (name, value, expDays) => {
  let d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
};

/**
 * @function getCookie
 * @param {*} cname 
 */
export const getCookie = (cname) => {
  let name = `${cname}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
 * @function deleteCookie
 * @param {*} name 
 */
export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};

/**
 * @function deleteAllCookies
 */
export const deleteAllCookies = () => {
  document.cookie.split(";").forEach((c) => {
    setCookie(c, "", -1);
  });
  // window.location.reload();
};