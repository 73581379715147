import { useMemo, useState } from 'react';

const UiHook = () => {
  const [openToastr, setOpenToastr] = useState(false);
  const [toastrMsg, setToastrMsg] = useState("");
  const [subscribeNowModal, setSubscribeNowModal] = useState(false);
  const [blockedModal, setBlockedModal] = useState(false);
  const [interestListDeleteModal, setInterestListDeleteModal] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [interestListDeleteProductID, setInterestListDeleteProductID] = useState('');
  const [bidDeleteModal, setBidDeleteModal] = useState(false);
  const [bidDeleteID, setBidDeleteID] = useState('');
  const [wishlistDeleteModal, setWishlistDeleteModal] = useState(false);
  const [wishlistDeleteID, setWishlistDeleteID] = useState('');
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [cancelOrderID, setCancelOrderID] = useState({});
  const [reportSellerModal, setReportSellerModal] = useState(false);
  const [reportSellerModalID, setReportSellerModalID] = useState('');
  const [productSeller,setProductSeller]=useState("");

  const toastrAction = useMemo(() => ({
    toastrOpen: (open, message = "") => {
      setOpenToastr(open);
      setToastrMsg(message)
    }
  }), []);

  const modalAction = useMemo(() => ({
    openSubscribeNowModal: () => {
      setSubscribeNowModal(true);
    },
    closeSubscribeNowModal: () => {
      setSubscribeNowModal(false);
    },
    openBlockedModal: () => {
      setBlockedModal(true);
    },
    closeBlockedModal: () => {
      setBlockedModal(false);
    },
    openInterestListDeleteModal: (productId) => {
      setInterestListDeleteModal(true);
      setInterestListDeleteProductID(productId);
    },
    closeInterestListDeleteModal: () => {
      setInterestListDeleteModal(false);
    },
    openBidDeleteModal: (id) => {
      setBidDeleteModal(true);
      setBidDeleteID(id);
    },
    closeBidDeleteModal: () => {
      setBidDeleteModal(false);
    },
    openWishlistDeleteModal: (id) => {
      setWishlistDeleteModal(true);
      setWishlistDeleteID(id);
    },
    closeWishlistDeleteModal: () => {
      setWishlistDeleteModal(false);
    },
    openCancelOrderModal: (data) => {
      setCancelOrderModal(true);
      setCancelOrderID(data);
    },
    closeCancelOrderModal: () => {
      setCancelOrderModal(false);
    },
    openReportSellerModal: (id,rid) => {
      setReportSellerModal(true);
      setReportSellerModalID(id);
      setProductSeller(rid)
    },
    closeReportSellerModal: () => {
      setReportSellerModal(false);
    }
  }), []);

  const loaderAction = useMemo(() => ({
    pageLoader: (status) => {
      setFullPageLoader(status);
    }
  }), []);

  return { 
    toastrAction, openToastr, toastrMsg, subscribeNowModal, 
    modalAction, blockedModal, interestListDeleteModal,
    loaderAction, fullPageLoader, interestListDeleteProductID,
    bidDeleteModal, bidDeleteID,
    wishlistDeleteModal, wishlistDeleteID,
    cancelOrderModal, cancelOrderID,
    reportSellerModal, reportSellerModalID,
    productSeller
  };
};

export default UiHook;